<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>VRDB Repertoire</h1>
      </div>
    </div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="float-right">
          <div class="text--bold float-left">Numbers last refreshed:&nbsp;</div>
          <div class="float-right">
            {{ last_refreshed }}
          </div>
        </div>
      </div>

      <div class="row card">
        <table>
          <sortable-head
            :bulk-enabled="false"
            :columns="columns"
            :default-sort-should-override="true"
            :more-options="false"
            @sort="sort"
          />

          <tbody>
            <template v-for="society in societies">
              <tr :key="society.id">
                <td>
                  <router-link v-if="society.id" :to="`/societies/${society.id}/vrdb/to_check`">
                    {{ society.name }}
                  </router-link>
                  <span v-else class="fs-14">{{ society.society_code }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.line_count) }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.to_check) }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.accepted) }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.rejected) }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.applied) }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.rejected_sent) }}</span>
                </td>
                <td>
                  <span>{{ $filters.formatNumber(society.not_matched) }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MatchingService from '../../services/matchingService';
import Spinner from '../spinner';
import SortableHead from '@/components/ui/table/sortable-head.vue';
import _ from 'lodash';

export default {
  name: 'ViewSocietiesOverview',
  components: {
    SortableHead,
    Spinner,
  },
  data() {
    return {
      viewRouteName: 'vrdbSocietiesOverview',
      loading: true,
      societies: null,
      last_refreshed: null,
      columns: [
        {
          name: 'Repertoire Manager',
          sortParam: 'society_code',
          thinColumn: true,
          ascending: true,
        },
        { name: 'Total', sortParam: 'line_count', thinColumn: true, ascending: true },
        { name: 'To Check', sortParam: 'to_check', thinColumn: true, ascending: true },
        { name: 'Accepted', sortParam: 'accepted', thinColumn: true, ascending: true },
        { name: 'Rejected', sortParam: 'rejected', thinColumn: true, ascending: true },
        { name: 'Applied', sortParam: 'applied', thinColumn: true, ascending: true },
        { name: 'Rejected Sent', sortParam: 'rejected_sent', thinColumn: true, ascending: true },
        { name: 'Not Matched', sortParam: 'not_matched', thinColumn: true, ascending: true },
      ],
    };
  },
  computed: {
    societyMetadata() {
      return this.$store.state.appdata.societies;
    },
  },
  async created() {
    await this.reloadBaseData();
  },
  methods: {
    sort(column) {
      const sortParam = column.sortParam;
      this.societies = _.orderBy(this.societies, sortParam, [column.order]);
    },
    async reloadBaseData() {
      const { societies, last_refreshed } = await MatchingService.getVrdbSocietyOverview();
      this.last_refreshed = last_refreshed ? new Date(last_refreshed).toLocaleString('swe') : '';
      this.societies = societies.map((header) => ({
        ...header,
        ...this.societyMetadata.find((s) => s.vrdb_code === header.society_code),
      }));
      this.loading = false;
    },
  },
};
</script>
