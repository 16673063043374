<template>
  <div>
    <form>
      <div>
        <table>
          <tr class="row">
            <td class="thin-column text--bold">Modify</td>
            <td class="text--bold">Attribute</td>
          </tr>
          <tbody>
            <tr v-for="[key, field] in Object.entries(fields)" :key="key" class="row">
              <td class="thin-column">
                <input :id="key" v-model="field.enabled" type="checkbox" class="filled-in" />
                <label :for="key" />
              </td>
              <td v-if="field.enabled">
                <label v-if="field.showLabelTitle">{{ capitalise(field.title) }}&nbsp;</label>
                <component
                  :is="field.type"
                  :id="`${key}-value`"
                  v-validate="field.validationRule && field.validationRule(fields, field)"
                  :name="field.title"
                  :value="field.getValue ? field.getValue(field) : field.value"
                  v-bind="field.props"
                  @input="(event) => field.valueEventHandler(field, event)"
                />
                <span v-if="field.mandatory" class="mandatory">(mandatory)</span>
                <label :for="`${key}-value`" />
              </td>
              <td v-else>Check the box to modify {{ field.title }}</td>
            </tr>
          </tbody>
        </table>
        <div class="push--top float-right">
          <action-buttons
            :disable-submit="
              !(
                Object.values(fields).some((f) => f.enabled) &&
                Object.values(fields)
                  .filter((f) => f.enabled)
                  .every((f) => f.canSave(f))
              )
            "
            @save="showModal = true"
            @cancel="$emit('cancel')"
          />
        </div>

        <ChangeTracksModal
          title="basic info"
          :items="
            Object.values(fields)
              .filter((f) => f.enabled)
              .map((f) => ({
                prop: f.title,
                value: f.valueToText(f),
              }))
          "
          :show="showModal"
          :count="count"
          @cancel="showModal = false"
          @save="onSave"
        />
      </div>
    </form>
  </div>
</template>

<script>
import SearchableInput from '@/components/ui/input/searchable-input.vue';
import SearchHelper from '@/components/search/searchHelper';
import { capitalise } from '@/common/typeUtils';
import ChangeTracksModal from '@/components/album/edit/bulk/change-tracks-modal';
import ActionButtons from '../../../ui/button/action-buttons';
import SelectCountry from '../../../ui/select/select-country';
import SelectInput from '../../../ui/select/select-input';
import SelectDate from '../../../ui/select/select-date';
import { formatCountry } from '@/filters';

export default {
  name: 'ChangeTracksBasicInfo',
  components: {
    ChangeTracksModal,
    SearchableInput,
    ActionButtons,
    SelectCountry,
    SelectInput,
    SelectDate,
  },
  inject: ['$validator'],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    tracks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: {
        version_title: {
          title: 'version title',
          showLabelTitle: true,
          value: '',
          enabled: false,
          type: 'input',
          props: {
            type: 'text',
            placeholder: 'E.g. "Live"',
            'v-validate': 'required',
          },
          valueToText: (field) => field.value,
          valueEventHandler: (field, event) => {
            field.value = event.target.value;
          },
          canSave: (field) => field.enabled,
        },
        main_artist: {
          title: 'main artist',
          showLabelTitle: false,
          value: null,
          enabled: false,
          type: 'searchable-input',
          props: {
            ref: 'name',
            name: 'name',
            label: 'New main artist',
            placeholder: 'E.g. Kent',
            'use-template': 'mainArtistNameSearch',
            searcher: this.searchMainArtist,
          },
          getValue: (field) => field.value && field.value.name,
          valueToText: (field) => field.value && field.value.name,
          valueEventHandler: (field, event) => {
            field.value = {
              id: event.id,
              name: event.name,
            };
          },
          canSave: (field) => field.enabled && field.value !== null,
        },
        label: {
          title: 'label',
          showLabelTitle: true,
          value: '',
          enabled: false,
          type: 'input',
          props: {
            type: 'text',
            placeholder: 'E.g. "BMG"',
          },
          valueToText: (field) => field.value,
          valueEventHandler: (field, event) => {
            field.value = event.target.value;
          },
          canSave: (field) => field.enabled,
        },
        produced_in_country: {
          title: 'produced in country',
          showLabelTitle: false,
          value: '',
          enabled: false,
          type: 'select-country',
          props: {
            name: 'produced_in_country',
            label: 'Produced in country',
          },
          valueToText: (field) => formatCountry(field.value),
          valueEventHandler: (field, event) => {
            field.value = event;
          },
          canSave: (field) => field.enabled,
        },
        recorded_in_country: {
          title: 'recorded in country',
          showLabelTitle: false,
          value: '',
          enabled: false,
          type: 'select-country',
          props: {
            name: 'recorded_in_country',
            label: 'Recorded in country',
          },
          valueToText: (field) => formatCountry(field.value),
          valueEventHandler: (field, event) => {
            field.value = event;
          },
          canSave: (field) => field.enabled,
        },
        recording_date: {
          title: 'recording date',
          showLabelTitle: true,
          value: '',
          enabled: false,
          type: 'input',
          props: {
            type: 'date',
            max: '2999-12-31',
          },
          valueToText: (field) => field.value,
          valueEventHandler: (field, event) => {
            field.value = event.target.value;
          },
          canSave: (field) => field.enabled,
        },
        release_date: {
          title: 'release date',
          showLabelTitle: true,
          value: '',
          enabled: false,
          type: 'input',
          props: {
            type: 'date',
            max: '2999-12-31',
          },
          valueToText: (field) => field.value,
          valueEventHandler: (field, event) => {
            field.value = event.target.value;
          },
          canSave: (field) => field.enabled,
        },
        genre: {
          title: 'genre',
          showLabelTitle: true,
          value: '',
          enabled: false,
          type: 'select-input',
          props: {
            name: 'genre',
            items: this.$store.state.appdata.referenceData.genres,
            value: this.field && this.fields.genre.value,
            'item-key': 'code',
            'item-value': 'name',
          },
          valueToText: (field) => field.value,
          valueEventHandler: (field, event) => {
            field.value = event;
          },
          canSave: (field) => field.enabled && !this.hasTrackWithLockedLineup,
        },
        composer: {
          title: 'composed by',
          showLabelTitle: true,
          value: '',
          enabled: this._hasClassic(),
          mandatory: this._hasClassic(),
          type: 'input',
          props: {
            type: 'text',
          },
          validationRule: (fields, field) => {
            if (field.mandatory) {
              return 'required';
            }
            return '';
          },
          valueToText: (field) => field.value,
          valueEventHandler: (field, event) => {
            field.value = event.target.value;
          },
          canSave: (field) => {
            if (field.mandatory && !field.value) {
              return false;
            }
            return field.enabled;
          },
        },
      },
      showModal: false,
    };
  },
  computed: {
    genre() {
      return this.fields.genre;
    },
    hasClassic() {
      return this._hasClassic();
    },
    hasTrackWithLockedLineup() {
      return this.tracks.some((track) => track.recordingAggregate.lineup_locked);
    },
  },
  watch: {
    tracks() {
      if (this.hasClassic) {
        this.fields.composer.enabled = true;
        this.fields.composer.mandatory = true;
      } else {
        this.fields.composer.mandatory = false;
      }
    },
    genre: {
      handler() {
        if (this.genre.enabled) {
          if (this.genre.value === 'classical_music') {
            this.fields.composer.enabled = true;
            this.fields.composer.mandatory = true;
          } else {
            this.fields.composer.mandatory = false;
          }
        } else {
          if (this.hasClassic) {
            this.fields.composer.enabled = true;
            this.fields.composer.mandatory = true;
          } else {
            this.fields.composer.mandatory = false;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    capitalise,
    _hasClassic() {
      return this.tracks.some((t) => t.recordingAggregate.basic_info.genre === 'classical_music');
    },
    onSave() {
      this.showModal = false;
      this.$emit('save', 'recording:patchBasicInfo', {
        basic_info: Object.fromEntries(
          Object.entries(this.fields)
            .filter(([_key, field]) => field.enabled)
            .map(([key, field]) => [key, field.value || null]),
        ),
      });
    },
    async searchMainArtist(name) {
      const res = await SearchHelper.advancedSearchMainArtists(name);
      return res && res.results;
    },
  },
};
</script>
