<template>
  <tbody v-if="localRows && localRows.length > 0">
    <template v-for="row in localRows">
      <tr ref="line" :key="`tr1-${row.id}`" class="cursor-pointer">
        <td class="compare__arrow" @click="toggleExpandedPerformer(row)">
          <span>
            <i v-if="isPerformerExpanded(row.id)" class="fas fa-caret-up" />
            <i v-else class="fas fa-caret-down" />
          </span>
        </td>
        <td
          v-if="[matchState.DISBURSE, matchState.REFUND].includes(state)"
          class="compare__checkbox"
        >
          <input
            :id="row.id"
            ref="mark"
            v-model="row.checked"
            class="filled-in"
            type="checkbox"
            name="mark"
            @click.stop
          />
          <label :for="row.id" @click.stop />
        </td>
        <td>{{ row.first_name + ' ' + row.last_name }}</td>
        <td>
          <span v-if="row.main_artists">
            {{ row.main_artists.join(', ') }}
          </span>
        </td>
        <td>{{ row.ipn }}</td>
        <td v-if="state === matchState.REFUND">
          {{ row.error }}
        </td>
        <td>
          <template v-if="row.tags">
            <span v-for="tag in row.tags" :key="tag" class="chip">
              {{ tag }}
            </span>
          </template>
        </td>
        <td class="amount-row">
          {{
            row.net_amount ||
            $filters.formatAmountThousandSeparator(row.gross_amount, statementHeader.currency)
          }}
        </td>
        <td class="amount-row">
          {{ $filters.formatAmountThousandSeparator(row.converted_amount, 'SEK') }}
          <i
            v-if="row.converted_amount < 0 && row.state === matchState.CHECK"
            class="fas fa-exclamation-triangle tooltip-icon negative"
          />
        </td>
      </tr>

      <template v-if="isPerformerExpanded(row.id)">
        <tr :key="`tr2-${row.id}`" class="prevent-hover-bg no-borders">
          <td class="hard--ends hard--sides" colspan="1000">
            <div class="compare__nested">
              <div class="compare__nested-main compare__main-divide">
                <div
                  class="compare__nested-heading compare__nested-heading--incoming"
                  style="margin-left: -24px"
                >
                  <strong>International source</strong>
                </div>
                <span class="compare__nested-heading compare__nested-heading--candidate">
                  <strong>Star suggestion</strong>
                </span>
                <ul v-if="row.topCandidate" class="compare__nested-list">
                  <CompareStatementGridRow
                    label="Name"
                    :incoming="resolveName(row)"
                    :candidate="resolveName(row.topCandidate)"
                    :link="`#/performers/${row.topCandidate.id}`"
                  />
                  <CompareStatementGridRow
                    label="IPN"
                    :incoming="row.ipn"
                    :candidate="row.topCandidate.ipn"
                  />
                  <CompareStatementGridRow
                    label="Date of birth"
                    :incoming="$filters.formatDate(row.date_of_birth)"
                    :candidate="row.topCandidate.date_of_birth"
                  />

                  <li class="compare__list-item">
                    <div class="compare__candidate">
                      <div
                        v-if="row.topCandidate.tags && row.topCandidate.tags.length > 0"
                        class="compare__candidate__tags"
                      >
                        <template v-for="(tag, index) in row.topCandidate.tags">
                          <tag :key="index" :tag="tag" />
                        </template>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <CompareStatementButtonsMatching
                v-if="state === matchState.CHECK"
                :row="row"
                @newCandidate="onNewCandidate(row, $event)"
                @candidateChanged="onCandidateChanged(row, $event)"
                @disburse="onDisburse"
                @refund="onRefund"
              />
              <div
                v-if="row.matchingDetails && row.matchingDetails.recordings"
                class="compare__wide-list"
              >
                <span class="compare__nested-heading">
                  <strong>Track information</strong>
                </span>
                <div class="compare__wide-list-headrow">
                  <div>Title</div>
                  <div>Main artists</div>
                  <div>Role codes</div>
                </div>
                <ul class="compare__wide-list-list">
                  <li
                    v-for="(recording, index) in row.matchingDetails.recordings"
                    :key="index"
                    class="compare__wide-list-list-item"
                  >
                    <div class="fs-14">
                      {{ recording.title }}
                    </div>
                    <div>{{ $filters.prettyPrintArray(recording.main_artists) }}</div>
                    <div>{{ $filters.formatOutgoingRoleCodes(recording.role_codes) }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </template>
  </tbody>
  <tbody v-else>
    <tr>
      <td colspan="1000" class="search-result__no-result">
        No rows with state {{ $t(`revenue.statement.match_state.${state}`) }}.
      </td>
    </tr>
  </tbody>
</template>

<script>
import _ from 'lodash';
import Tag from '../../tags/tag';
import StatementService from '../../../services/statementService';
import PerformerService from '../../../services/performerService';
import CompareStatementGridRow from '../../compare/compare-statement-grid-row';
import CompareStatementButtonsMatching from './compare-statement-buttons-matching';
import { performerMatchState as matchState } from '../../../domain/matchingDomain';

export default {
  name: 'ComparePerformersStatement',
  components: {
    CompareStatementGridRow,
    CompareStatementButtonsMatching,
    Tag,
  },
  props: {
    localRows: {
      type: Array,
      default: () => [],
    },
    statementHeader: {
      type: Object,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    markedForBulk: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allowAction: this.actionEnabled,
      expandedPerformers: [],
      matchState,
    };
  },
  watch: {
    localRows(a, b) {
      if (a.length !== b.length) {
        this.expandedPerformers = [];
      }
    },
  },
  methods: {
    async fetchPerformerMatchingDetails(statementId, groupId) {
      const result = await StatementService.fetchPerformerMatchingDetails(statementId, groupId);
      if (result) {
        return _.cloneDeep(result);
      }
      return {};
    },
    async fetchPerformer(performerId) {
      const result = await PerformerService.getPerformer(performerId);
      return {
        date_of_birth: result.general_info.date_of_birth,
        first_name: result.general_info.first_name,
        id: result.id,
        ipn: result.membership_info.ipn,
        last_name: result.general_info.last_name,
      };
    },
    async handleExpansion(row) {
      if (row.state === matchState.CHECK) {
        row.matchingDetails = await this.fetchPerformerMatchingDetails(
          this.statementHeader.id,
          row.group_id,
        );
        row.topCandidate = row.matchingDetails.hits.length > 0 ? row.matchingDetails.hits[0] : {};
      } else if (row.performer_id) {
        row.topCandidate = await this.fetchPerformer(row.performer_id);
      } else {
        row.topCandidate = {};
      }
    },
    isPerformerExpanded(id) {
      return this.expandedPerformers.includes(id);
    },
    async toggleExpandedPerformer(row) {
      if (!this.expandedPerformers.includes(row.id)) {
        await this.handleExpansion(row);
        this.expandedPerformers.push(row.id);
      } else {
        this.expandedPerformers = _.without(this.expandedPerformers, row.id);
      }
    },
    resolveName(obj) {
      if (!obj) {
        return '';
      }
      if (obj.name) {
        return obj.name;
      }
      let str = obj.first_name ? obj.first_name : '';
      if (obj.last_name) {
        str += ` ${obj.last_name}`;
      }
      return str.trim();
    },
    onCandidateChanged(row, candidate) {
      this.$emit('candidateChanged', row, candidate);
    },
    onNewCandidate(row, candidate) {
      this.$emit('newCandidate', row, candidate);
    },
    async onDisburse(row) {
      await this.changePerformerMatchState(
        this.statementHeader.id,
        [row],
        this.matchState.DISBURSE,
      );
      this.expandedPerformers = [];
      this.$emit('fetchData');
    },
    async onRefund(row) {
      await this.changePerformerMatchState(this.statementHeader.id, [row], this.matchState.REFUND);
      this.expandedPerformers = [];
      this.$emit('fetchData');
    },
    createChangePerformerMatchStateCommand(statementHeaderId, rows, state) {
      const command = {
        statement_header_id: statementHeaderId,
        items: rows.map((row) => ({
          performerId: row.topCandidate ? row.topCandidate.id : row.performer_id,
          groupId: row.group_id,
          state,
        })),
      };
      return command;
    },
    async changePerformerMatchState(statementHeaderId, rows, state) {
      await StatementService.changePerformerMatchState(
        this.createChangePerformerMatchStateCommand(statementHeaderId, rows, state),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.compare {
  &__wide-list {
    grid-area: tracks;

    &-headrow {
      grid-template-columns: 2fr repeat(2, 1fr);
    }
    &-list-item {
      grid-template-columns: 2fr repeat(2, 1fr);
    }
  }
  &__candidate {
    &__tags {
      display: -webkit-box;
      margin-top: 5px;
      margin-left: -5px;
    }
  }
}
</style>
