<template id="app">
  <div>
    <generic-error />
    <generic-modal />

    <div v-if="$route.name === 'authentication'" id="app__main-container-auth">
      <div id="master-content-auth" class="row">
        <div class="col s12">
          <router-view />
        </div>
      </div>
    </div>

    <div v-else id="app__main-container">
      <header-bar id="header-bar" />

      <side-nav :route="activeRoute" />

      <div id="master-content" class="row">
        <div class="col s12">
          <spinner v-if="contentLoading" />
          <router-view v-if="appDataInitiated" />
        </div>
      </div>
    </div>

    <version-refresh />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GenericError from './components/errors/generic-error';
import GenericModal from './components/errors/generic-modal';
import HeaderBar from './components/header-bar';
import SideNav from './components/side-nav';
import Spinner from './components/spinner';
import VersionRefresh from '@/components/version-refresh/version-refresh.vue';

export default {
  name: 'App',
  components: {
    VersionRefresh,
    GenericError,
    GenericModal,
    HeaderBar,
    SideNav,
    Spinner,
  },
  data() {
    return {
      activeRoute: '',
    };
  },
  computed: {
    ...mapGetters('starNotifications', ['loadingState']),
    appDataInitiated() {
      return this.$store.state.appdata.initiated;
    },
    contentLoading() {
      return this.loadingState.content;
    },
  },
  updated() {
    this.activeRoute = this.$route.path;
  },
  errorCaptured(error) {
    if (error.type === 'serverError') {
      this.$addStarError(error);
      return false;
    }
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import 'assets/sass/application-style';
#app {
  &__main-container {
    position: relative;
  }
}
#master-content {
  margin-left: var(--spacing-sevenfold);
  margin-top: var(--spacing);
  padding: 0 var(--spacing-triple);
  transition: all 400ms;
}
#header-bar {
  border-bottom: 2px solid var(--white);
  z-index: 9001;
  position: relative;
}
</style>
