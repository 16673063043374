<template>
  <tbody>
    <tr>
      <td class="thin-column dropdown-button__arrow" @click="$emit('toggleExpanded')">
        <i :class="`fas fa-caret-${expanded ? 'up' : 'down'}`"></i>
      </td>
      <td>
        <input
          :id="item.stream_id"
          ref="mark"
          v-model="item.checked"
          class="filled-in"
          type="checkbox"
          name="mark"
          @click.stop
        />
        <label :for="item.stream_id" @click.stop />
      </td>
      <td>
        <span>{{ item.payload.name }}</span>
      </td>
      <td v-if="mergeType === 'album'">
        <span v-if="item.payload.main_artist">
          {{ item.payload.main_artist }}
        </span>
        <span v-else class="none">None</span>
      </td>
      <td>
        <span>{{ $filters.formatDate(item.detected) }}</span>
      </td>
      <td>
        <span>{{ item.nr_duplicates }}</span>
      </td>
    </tr>
    <tr v-if="expanded" class="prevent-hover-bg">
      <td></td>
      <td colspan="100%" class="expanded">
        <table>
          <SortableHead
            :expanded-content="true"
            :more-options="false"
            :columns="columns[mergeType]"
            :bulk-enabled="false"
            :default-sort-should-override="true"
          />

          <DuplicateMatch
            v-for="match in matches"
            :key="match.id"
            :merge-type="mergeType"
            :match="match"
            @addDuplicateBlockedId="addDuplicateBlockedId"
            @updateDuplicateStreamId="updateDuplicateStreamId"
          />

          <tr>
            <td colspan="100%">
              <div class="col s9 chips__wrapper">
                <tag v-for="tag of item.tags" :key="tag" :tag="tag" />
              </div>
              <div class="col s3">
                <button
                  :disabled="matches.length < 2"
                  type="button"
                  class="btn float-right"
                  @click="previewMerge"
                >
                  Preview Merge
                </button>
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</template>

<script>
import SortableHead from '../ui/table/sortable-head';
import MergeService from '../../services/mergeService';
import DuplicateMatch from './duplicate-match';
import Tag from '../tags/tag';

export default {
  name: 'DuplicatesItem',
  components: {
    SortableHead,
    DuplicateMatch,
    Tag,
  },
  props: {
    mergeType: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      masterId: '',
      matches: [],
      columns: {
        album: [
          {
            name: 'Name',
            ascending: true,
            sortParam: 'name',
            active: true,
            default: true,
          },
          {
            name: 'Main artist',
            ascending: true,
            sortParam: 'main_artist',
            active: false,
            default: false,
          },
          { name: 'Catalogue no' },
          { name: 'Release date' },
          { name: 'Label' },
          { name: 'Number of tracks' },
          { thinColumn: true },
          { thinColumn: true },
        ],
        mainartist: [
          {
            name: 'Name',
            ascending: true,
            sortParam: 'name',
            active: true,
            default: true,
          },
          { name: 'Alternate names' },
          { name: 'Origin' },
          { name: 'Members' },
          { thinColumn: true },
          { thinColumn: true },
        ],
      },
    };
  },
  watch: {
    expanded(value) {
      if (value) {
        this.masterId = this.masterId || this.item.stream_id;
        this.fetchData();
      }
    },
  },
  methods: {
    async updateDuplicateStreamId(toStreamId) {
      try {
        await MergeService.updateDuplicateStreamId(this.masterId, toStreamId);
        this.masterId = toStreamId;
        this.matches = this.mapMatches(this.matches);
      } catch (err) {
        this.$addStarError(err);
      }
    },
    async fetchData() {
      try {
        const response = await MergeService.getMatches(this.masterId, this.mergeType);
        this.masterId = response.stream_id;
        this.matches = this.mapAndSortMatches(response.matches);
      } catch (err) {
        this.$addStarError(err);
      }
    },
    mapAndSortMatches(matches) {
      return matches
        .map((match) => ({ ...match, master: match.id === this.masterId }))
        .sort((match) => (match.master ? -1 : 1));
    },
    mapMatches(matches) {
      return matches.map((match) => ({ ...match, master: match.id === this.masterId }));
    },
    getPreviewRouteName() {
      switch (this.mergeType) {
        case 'album':
          return 'mergePreviewAlbum';
        case 'mainartist':
          return 'mergePreviewMainArtist';
        default:
          throw new Error(`Unknown merge type: ${this.mergeType}`);
      }
    },
    previewMerge() {
      this.$router.push({
        name: 'mergePreview',
        params: { id: this.masterId, mergeType: this.mergeType },
      });
    },
    async addDuplicateBlockedId(blockedId) {
      try {
        await MergeService.addDuplicateBlockedId(this.masterId, blockedId);
        this.matches = this.matches.filter((match) => match.id !== blockedId);
      } catch (err) {
        this.$addStarError(err);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.expanded {
  border: 1px solid #eee;
}
</style>
