<template>
  <div class="e2e-basic-information card">
    <div class="row small-inner text--right edit-link">
      <div class="col s12">
        <a :class="{ disabled: !editable }" @click="$emit('edit', $options.name)">
          <i class="far fa-edit"></i>
          Edit
        </a>
      </div>
    </div>
    <div class="header">
      <div class="row small-inner text--bold total-amount">
        <div class="col s2">Total amount</div>
        <div class="col s10">
          <span v-if="amount">
            {{ $filters.formatAmountThousandSeparator(amount, 'SEK') }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div v-if="originalAmount" class="row small-inner">
        <div class="col s2 text--bold">Original amount</div>
        <div class="col s9">
          <span>
            {{ $filters.formatAmountThousandSeparator(originalAmount, currency || 'SEK') }}
          </span>
        </div>
      </div>
      <div v-if="conversionRate" class="row small-inner">
        <div class="col s2 text--bold">Conversion rate</div>
        <div class="col s9">
          <span>
            {{ $filters.formatDecimals(conversionRate, 4) }}
          </span>
        </div>
      </div>

      <div v-if="statementTotalAmount" class="row small-inner">
        <div class="col s2 text--bold">Statement amount</div>
        <div class="col s9">
          <span :class="statementAmountDiff ? 'text--error text--bold' : ''">
            {{ $filters.formatAmountThousandSeparator(statementTotalAmount, currency || 'SEK') }}
          </span>
        </div>
      </div>
      <div
        v-if="source.category === categories.INTERNATIONAL && statementConversionRate"
        class="row small-inner"
      >
        <div class="col s2 text--bold">Statement Conversion rate</div>
        <div class="col s9">
          <span :class="statementConversionDiff ? 'text--error text--bold' : ''">
            {{ $filters.formatDecimals(statementConversionRate, 4) }}
          </span>
        </div>
      </div>
      <template v-if="source.category === categories.DOMESTIC">
        <div class="row small-inner">
          <div class="col s2 text--bold">
            {{ $filters.translate(`common.administrative_cost`) }} %
          </div>
          <div class="col s9">
            <span>{{ $filters.formatDecimals(financingRate || '0') }}%</span>
          </div>
        </div>
        <div class="row small-inner">
          <div class="col s2 text--bold">
            {{ $filters.translate(`common.administrative_cost`) }}
          </div>
          <div class="col s9">
            <span v-if="financingFee">
              {{
                $filters.formatAmountThousandSeparator(
                  $filters.flipSign(financingFee),
                  currency || 'SEK',
                )
              }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
      </template>
      <div class="row small-inner">
        <div class="col s2 text--bold">
          {{ $filters.translate(`common.distributable_amount`) }}
        </div>
        <div class="col s9">
          <span v-if="distributableAmount" class="text--bold">
            {{ $filters.formatAmountThousandSeparator(distributableAmount, 'SEK') }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">
          {{ $filters.translate(`common.distributed_amount`) }}
        </div>
        <div class="col s9">
          <span v-if="distributedAmount" class="text--bold">
            {{ $filters.formatAmountThousandSeparator(distributedAmount, 'SEK') }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">
          {{ $filters.translate(`common.refunded_amount`) }}
        </div>
        <div class="col s9">
          <span v-if="refundedAmount" class="text--bold">
            {{ $filters.formatAmountThousandSeparator(refundedAmount, 'SEK') }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">Year</div>
        <div class="col s9">
          <span v-if="year">
            {{ year }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">Payment date</div>
        <div class="col s9">
          <span v-if="paymentDate">
            {{ $filters.formatDate(paymentDate) }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">Source</div>
        <div class="col s9">
          <span>
            {{ source.name }}
          </span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">Statement</div>
        <div class="col s9">
          <span
            v-if="
              statementUrl &&
              getStatement() &&
              [statementState.CREATED, statementState.FINISHED].includes(getStatement().state)
            "
          >
            <a @click="onDownloadStatement(statementUrl)">
              <i class="far fa-file-alt"></i>
              {{ getFileName(statementUrl) }}
            </a>
          </span>
          <span
            v-else-if="getStatement() && getStatement().state === statementState.ERROR"
            class="text--error"
          >
            Parse error. Please validate the file contents and try again.
          </span>
          <span v-else-if="getStatement() && getStatement().state === statementState.PARSING">
            {{ getStatement().progress * 100 }} %
          </span>
          <span v-else-if="aggregate.statement_url">In progress...</span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">Attachments</div>
        <div class="col s9">
          <span v-if="attachmentUrls && attachmentUrls.length > 0">
            <div v-for="(attachmentUrl, index) in attachmentUrls" :key="index">
              <a @click="onDownloadFile(attachmentUrl)">
                <i class="far fa-file-alt"></i>
                {{ getFileName(attachmentUrl) }}
              </a>
            </div>
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s2 text--bold">Note</div>
        <div class="col s9">
          <span v-if="note">
            {{ note }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div v-if="refundFiles.length" class="row small-inner">
        <div class="col s2 text--bold">Feedback Files</div>
        <div class="col s9">
          <div v-for="(file, index) of refundFiles" :key="index">
            <a :href="file.url">
              <i class="far fa-file-alt"></i>
              {{ file.filename }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="stateSummary?.summaries?.length > 0" class="summary">
      <table class="summary vertical">
        <thead>
          <tr>
            <th></th>
            <th>Net amount</th>
            <th>Converted net amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <div>
                To Check
                <i
                  v-if="stateSummary?.warnings.containsNegativeConvertedAmount"
                  v-tooltip.top="issueText()"
                  class="fas fa-exclamation-triangle negative"
                />
              </div>
            </th>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('C').amount_total,
                  currency || 'SEK',
                )
              }}
            </td>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('C').converted_amount_total,
                  'SEK',
                )
              }}
            </td>
          </tr>
          <tr>
            <th>Disburse</th>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('D').amount_total,
                  currency || 'SEK',
                )
              }}
            </td>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('D').converted_amount_total,
                  'SEK',
                )
              }}
            </td>
          </tr>
          <tr>
            <th>Refund</th>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('R').amount_total,
                  currency || 'SEK',
                )
              }}
            </td>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('R').converted_amount_total,
                  'SEK',
                )
              }}
            </td>
          </tr>
          <tr>
            <th>Transferred</th>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('T').amount_total,
                  currency || 'SEK',
                )
              }}
            </td>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('T').converted_amount_total,
                  'SEK',
                )
              }}
            </td>
          </tr>
          <tr>
            <th>Refunded</th>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('X').amount_total,
                  currency || 'SEK',
                )
              }}
            </td>
            <td>
              {{
                $filters.formatAmountThousandSeparator(
                  getSummary('X').converted_amount_total,
                  'SEK',
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="specification && specification.length > 0" class="row">
      <table>
        <thead>
          <tr>
            <th class="text--bold">Source</th>
            <th class="text--bold">Amount to allocate</th>
            <th class="text--bold">Distributed</th>
          </tr>
        </thead>
        <tr v-for="(subSource, index) in specification" :key="index">
          <td class="fs-14">
            {{ subSource.name }}
          </td>
          <td>
            {{
              $filters.formatAmountThousandSeparator(subSource.amount ? subSource.amount : 0, 'SEK')
            }}
          </td>
          <td>-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DownloadLink from '../../ui/file/downloadLink';
import RemoteFileService from '../../../services/remoteFileService';
import { statementState } from '../../../domain/statementDomain';
import { DistributionCategory } from '../../../domain/distributionDomain';
import extractRemoteFileName from '../../../common/extractRemoteFileName';

export default {
  name: 'BasicInformation',
  components: {},
  props: {
    currentState: {
      type: String,
      default: '',
    },
    source: {
      type: Object,
      default: () => {},
    },
    stateSummary: {
      type: Object,
    },
  },
  data() {
    return {
      editable: false,
      statementState,
      categories: DistributionCategory,
      statementAmountDiff: false,
      statementConversionDiff: false,
    };
  },
  computed: {
    ...mapGetters('revenue', [
      'id',
      'aggregate',
      'name',
      'state',
      'year',
      'currency',
      'paymentDate',
      'statementUrl',
      'statementTotalAmount',
      'statementConversionRate',
      'attachmentUrls',
      'statementFileHandle',
      'note',
      'amount',
      'originalAmount',
      'distributableAmount',
      'distributedAmount',
      'refundedAmount',
      'sourceId',
      'conversionRate',
      'financingFee',
      'financingRate',
      'specification',
      'missingPerformersUrl',
      'statementFeedbackFileUrl',
    ]),
    refundFiles() {
      return (this.getStatement() && this.getStatement().refund_files) || [];
    },
  },
  watch: {
    currentState() {
      this.editable = this.currentState === 'NEW';
    },
  },
  async created() {
    this.editable = this.currentState === 'NEW';
    this.statementAmountDiff =
      this.aggregate.original_amount && this.aggregate.statement_total_amount
        ? this.aggregate.statement_total_amount !== this.aggregate.original_amount
        : false;
    this.statementConversionDiff =
      this.aggregate.statement_conversion_rate && this.aggregate.conversion_rate
        ? this.aggregate.statement_conversion_rate.toFixed(2) !==
          this.aggregate.conversion_rate.toFixed(2)
        : false;
  },
  methods: {
    issueText() {
      return {
        content: 'Contains negative amount',
        html: true,
      };
    },
    getSummary(state) {
      return (
        this.stateSummary?.summaries.find((summary) => summary.state === state) || {
          amount_total: 0,
          converted_amount_total: 0,
        }
      );
    },
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    async onDownloadFile(url) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
      DownloadLink.direct(fileData, url);
    },
    async onDownloadStatement(url) {
      const fileData = await RemoteFileService.downloadStatementFile(url);
      DownloadLink.direct(fileData, url);
    },
    async onDownloadFeedbackFile(url) {
      const fileData = await RemoteFileService.onDownloadFeedbackFile(url);
      DownloadLink.direct(fileData, url);
    },
    getStatement() {
      if (this.aggregate.statements && this.aggregate.statements.length > 0) {
        return this.aggregate.statements[0];
      }
      return null;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.e2e-basic-information {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > :first-child {
    flex: 0 0 100%;
  }

  .header {
    flex: 1 1 530px;
  }
}

.total-amount {
  font-size: 14px;

  .col {
    border-bottom: 1px solid var(--grey--vlight);
  }
}

.summary {
  flex: 1 0 350px;

  table {
    th,
    td {
      font-size: 12px;
    }

    th,
    td,
    tr,
    thead,
    tbody {
      border: none;
      box-shadow: none;
    }

    thead {
      border-bottom: 1px solid var(--grey--vlight);

      th {
        cursor: default;
        padding: 0;
        font-size: 14px;

        &::before {
          content: unset;
        }
      }
    }

    tbody {
      th {
        padding-right: 1rem;
      }

      th,
      td {
        padding: 4px 0;
      }

      tr {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
</style>
