<template>
  <form class="search-result__everything">
    <div class="search-result__filters card">
      <div class="row">
        <div class="col s4">
          <label class="label">Search</label>
          <clearable-input
            v-model="searchTerms.query"
            type="search"
            name="filter"
            placeholder=""
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <select-date-modified
            :value="searchTerms.dateModified.code"
            @input="searchTerms.dateModified = $event"
          />
        </div>
        <div class="col s3">
          <select-country v-model="searchTerms.countryCode" :input-countries="localCountries" />
        </div>
        <div class="col s3">
          <searchable-tag-input
            name="tags"
            label="Tags"
            placeholder="E.g. Top 100"
            :preview-list="previewTags"
            :value-list="localTags"
            :searcher="searchTag"
            scope="advanced-search-everything"
            use-template="tag"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
      </div>
      <div class="row flush--bottom">
        <div class="col s12 text--right">
          <action-buttons
            submit-label="Search"
            abort-label="Clear"
            @save="onSearchClick"
            @cancel="onClearClick"
          />
        </div>
      </div>
    </div>

    <div v-show="searchContext.isExecuted" class="search-result__summary">
      Search for
      <span v-if="searchContext.query != ''">{{ searchContext.query }}</span>
      <span v-else>*</span>
      in
      <span>Everything</span>
      gave {{ $filters.formatNumber(searchContext.result.totalHits) }} results
    </div>

    <SearchQueryTooLong
      :length-limit="searchContext.searchLengthLimit"
      :query-too-long="searchContext.isTooLong"
    />

    <!-- BEGIN RESULT ITERATION -->
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="search-result__result card row">
        <div v-if="!searchContext.isExecuted" class="row flush--bottom" />
        <template v-else>
          <div
            v-for="(typedResult, idx) in searchContext.result.typedResults"
            :key="idx"
            class="row"
          >
            <div
              v-if="typedResult.type === 'performer'"
              class="col s12 search-result__everything__result"
            >
              <everything-result-performer :typed-result="typedResult" />
            </div>

            <div
              v-if="typedResult.type === 'recording'"
              class="col s12 search-result__everything__result"
            >
              <everything-result-recording :typed-result="typedResult" />
            </div>

            <div
              v-if="typedResult.type === 'mainartist'"
              class="col s12 search-result__everything__result"
            >
              <everything-result-main-artist :typed-result="typedResult" />
            </div>

            <div
              v-if="typedResult.type === 'album'"
              class="col s12 search-result__everything__result"
            >
              <everything-result-album :typed-result="typedResult" />
            </div>

            <div
              v-if="typedResult.type === 'associate'"
              class="col s12 search-result__everything__result"
            >
              <everything-result-associate :typed-result="typedResult" />
            </div>

            <div
              v-if="typedResult.type === 'society'"
              class="col s12 search-result__everything__result"
            >
              <everything-result-society :typed-result="typedResult" />
            </div>
          </div>
          <div v-if="searchContext.isExecuted">
            <div class="col s12">
              <pagination
                v-show="numberOfPages > 0"
                topic="search"
                :number-of-pages="numberOfPages"
                :selected-page="pagination.page"
                :number-of-hits="searchContext.result.totalHits"
                :hits-per-page="pagination.hitsPerPage"
                :range-limit="true"
                @selectPage="selectPage"
                @updateHitsPerPage="updateHitsPerPage"
              />
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- END RESULT ITERATION -->
    <template v-if="searchContext.isExecuted && searchContext.result.totalHits === 0">
      <tr>
        <td colspan="1000" class="search-result__no-result">No items found.</td>
      </tr>
    </template>
  </form>
</template>

<script>
import _ from 'lodash';
import ActionButtons from '../ui/button/action-buttons';
import EverythingResultAlbum from './everything-result-album';
import EverythingResultAssociate from './everything-result-associate';
import EverythingResultMainArtist from './everything-result-main-artist';
import EverythingResultPerformer from './everything-result-performer';
import EverythingResultRecording from './everything-result-recording';
import EverythingResultSociety from './everything-result-society';
import Pagination from './../pagination';
import SearchHelper from './searchHelper';
import SearchableTagInput from './../ui/tag/searchable-tag-input';
import SelectCountry from '../ui/select/select-country';
import SelectDateModified from '../ui/select/select-date-modified';
import Spinner from '../spinner';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';

export default {
  name: 'AdvancedSearchEverything',
  components: {
    ActionButtons,
    EverythingResultAlbum,
    EverythingResultAssociate,
    EverythingResultMainArtist,
    EverythingResultPerformer,
    EverythingResultRecording,
    EverythingResultSociety,
    Pagination,
    SearchableTagInput,
    SelectCountry,
    SelectDateModified,
    Spinner,
    ClearableInput,
    SearchQueryTooLong,
  },
  data() {
    return {
      loading: false,
      localCountries: [],
      allTags: [],
      localTags: [],
      defaultHitsPerPage: 25,
      showFullProfile: false,
      searchTerms: {
        query: '',
        dateModified: { from: null, to: null, code: -1 },
        countryCode: '',
        tags: [],
      },
      searchContext: {
        query: '',
        isExecuted: false,
        isTooLong: false,
        searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
        result: {
          totalHits: 0,
          typedResults: [],
        },
      },
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    previewTags() {
      return this.allTags
        .filter((tag) =>
          this.localTags.length > 0
            ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
            : true,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
    },
    firstHitIndexOnCurrentPage() {
      if (this.searchContext.result.totalHits === 0) {
        return 0;
      }
      return Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage + 1;
    },
    lastHitIndexOnCurrentPage() {
      const totalHits = this.searchContext.result.totalHits;
      const lastHitIndex =
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage +
        this.pagination.hitsPerPage;
      return lastHitIndex < totalHits ? lastHitIndex : totalHits;
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    $route() {
      this.updateRouterSearchParams();
      if (this.$router.currentRoute.query.i) {
        this.search();
      } else {
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        this.$emit('updateIsExecuted', false);
      }
    },
  },
  async created() {
    this.localCountries = this.countries.slice();
    this.allTags = await SearchHelper.getAllTags();
    if (this.$router.currentRoute.query.i) {
      this.updateRouterSearchParams();
      this.search();
    }
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.searchTerms.tags = event.data.map((tag) => tag.name);
      this.localTags = event.data;
    },
    removeTagAt(event) {
      this.searchTerms.tags.splice(event.idx, 1);
    },
    onSearchClick() {
      this.resetPages();
      this.pushSearch();
    },
    onClearClick() {
      this.$router.push({
        name: 'advancedSearchEverythingView',
        query: '',
      });
    },
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterSearchParams() {
      this.searchTerms.query = this.$router.currentRoute.params.query
        ? this.$router.currentRoute.params.query
        : '';
      this.pagination.page = this.$router.currentRoute.query.page
        ? Number(this.$router.currentRoute.query.page)
        : 1;
      this.pagination.hitsPerPage = this.$router.currentRoute.query.hits
        ? Number(this.$router.currentRoute.query.hits)
        : this.defaultHitsPerPage;
      this.searchTerms.dateModified.from = this.$router.currentRoute.query.from
        ? new Date(this.$router.currentRoute.query.from)
        : null;
      if (this.$router.currentRoute.query.to) {
        const toDate = new Date(this.$router.currentRoute.query.to);
        toDate.setHours(24, 0, 0, 0);
        this.searchTerms.dateModified.to = toDate;
      } else {
        this.searchTerms.dateModified = { from: null, to: null, code: -1 };
      }
      this.searchTerms.dateModified.code = this.$router.currentRoute.query.dc
        ? Number(this.$router.currentRoute.query.dc)
        : -1;
      this.searchTerms.countryCode = this.$router.currentRoute.query.country
        ? this.$router.currentRoute.query.country
        : '';
      this.searchTerms.tags = this.$router.currentRoute.query.tags
        ? this.$router.currentRoute.query.tags
        : [];
      if (this.$router.currentRoute.query.tags) {
        if (_.isArray(this.$router.currentRoute.query.tags)) {
          this.localTags = this.$router.currentRoute.query.tags.map((tag) => ({
            name: tag,
            type: 'tag',
          }));
        } else {
          this.localTags = [{ name: this.$router.currentRoute.query.tags, type: 'tag' }];
        }
      } else {
        this.localTags = [];
      }
    },
    pushSearch() {
      const queryParams = {
        i: new Date().getTime(),
      };

      if (this.searchTerms.dateModified.from) {
        queryParams.from = this.searchTerms.dateModified.from.toISOString().substring(0, 10);
      }

      if (this.searchTerms.dateModified.to) {
        queryParams.to = this.searchTerms.dateModified.to.toISOString().substring(0, 10);
      }

      if (this.searchTerms.dateModified.code) {
        queryParams.dc = this.searchTerms.dateModified.code;
      }

      if (this.searchTerms.countryCode) {
        queryParams.country = this.searchTerms.countryCode;
      }

      if (this.searchTerms.tags) {
        queryParams.tags = this.searchTerms.tags;
      }

      if (this.pagination.hitsPerPage) {
        queryParams.hits = this.pagination.hitsPerPage;
      }

      if (this.pagination.page) {
        queryParams.page = this.pagination.page;
      }

      if (this.searchTerms.query !== '') {
        this.$router.push({
          name: 'advancedSearchEverythingQueryView',
          params: { query: this.searchTerms.query },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'advancedSearchEverythingView',
          query: queryParams,
        });
      }
    },
    search() {
      this.searchContext.query = this.searchTerms.query;
      if (SearchHelper.isQueryTooLong(this.searchTerms.query)) {
        this.searchContext.isTooLong = true;
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        return;
      }
      this.searchContext.isTooLong = false;
      this.loading = true;
      const dateFrom = this.searchTerms.dateModified.from
        ? this.searchTerms.dateModified.from.getTime()
        : null;
      const dateTo = this.searchTerms.dateModified.to
        ? this.searchTerms.dateModified.to.getTime()
        : null;
      SearchHelper.advancedSearchAll(
        this.searchTerms.query,
        dateFrom,
        dateTo,
        this.searchTerms.countryCode,
        this.searchTerms.tags,
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage,
        this.pagination.hitsPerPage,
      )
        .then((result) => {
          this.searchContext.isExecuted = true;
          this.$emit('updateIsExecuted', true);
          this.searchContext.result = result;
        })
        .then(() => {
          this.loading = false;
        });
    },
    resetPages() {
      this.pagination.page = 1;
    },
  },
};
</script>

<style>
.search-result__everything__result .entity-badge {
  float: left;
}
</style>
