<template>
  <div>
    <div class="row">
      <div class="col s12">
        <TableOptions
          :bulk-action-enabled="!!selected.length"
          :bulk-actions="line.bulkActions"
          :range="range"
          :custom-select="customSelect()"
          @rangeStartChanged="setRangeStart"
          @rangeEndChanged="setRangeEnd"
          @bulkAction="bulkAction"
          @customSelectChanged="onCustomSelectChanged($event)"
        />
      </div>
    </div>

    <div class="row card">
      <table>
        <SortableHead
          :columns="config.columns"
          :bulk-enabled="!!line.bulkActions"
          :default-sort-should-override="true"
          :more-options="false"
          :expanded-content="!line.disableExpand"
          :marked-for-bulk="selected.length === itemCount && itemCount > 0"
          @sort="sort"
          @markAll="toggleSelectAll"
        />
        <tbody v-if="!loading">
          <template v-for="(l, index) of lines">
            <ReportLine
              :ref="l[config.lineKey]"
              :key="'rl' + index"
              :line="l"
              :bulk-enabled="!!line.bulkActions"
              :expanded-content="shouldExpand"
              :selected="selected.includes(l[config.lineKey])"
              :state="line.state"
              @expand="
                expandedKey = expandedKey !== l[config.lineKey] ? l[config.lineKey] : undefined
              "
              @select="toggleSelect(l[config.lineKey])"
            />
            <CompareLine
              v-if="expandedKey === l[config.lineKey]"
              :key="'cl' + index"
              :line="l"
              :handle-new="handleNew"
              :identify="identify"
              :revert-match="revertMatch"
              :save="save"
              @ready="focusCurrentLine"
            />
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100">
              <ComponentSpinner />
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :number-of-pages="numberOfPages()"
        :selected-page="page"
        :number-of-hits="itemCount"
        :hits-per-page="pageSize"
        @selectPage="selectPage"
        @updateHitsPerPage="selectPageSize"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mutate, query } from '@/services/apolloRequest';
import TableOptions from '../ui/table/table-options';
import PaginationQueryParamMixin from '../../common/paginationQueryParamMixin';
import Pagination from '../pagination';
import ComponentSpinner from '../component-spinner';
import SortableHead from '../ui/table/sortable-head';
import CompareLine from './compare-line';
import ReportLine from './report-line';
import config from './config';

export default {
  name: 'ReportLines',
  components: {
    TableOptions,
    Pagination,
    SortableHead,
    ComponentSpinner,
    CompareLine,
    ReportLine,
  },
  mixins: [PaginationQueryParamMixin],
  props: {
    reportName: String,
  },
  data() {
    return {
      lines: [],
      loading: false,
      selected: [],
      expandedKey: undefined,
    };
  },
  computed: {
    config() {
      return config[this.$route.params.type];
    },
    line() {
      return this.config.lines.find((l) => l.name.toLowerCase() === this.$route.params.state);
    },
    shouldExpand() {
      const state = this.$route.params.state;
      return ['matched', 'unmatched', 'candidates'].includes(state);
    },
    range() {
      return {
        start: this.rangeStart,
        end: this.rangeEnd,
      };
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        // this.resetRange();
        this.selectPage(1);
        this.selected = [];
      }
      if (to.query.page !== from.query.page) {
        this.selected = [];
      }
      this.fetchReportLines().then(() => {
        // expand first or last row on the new page
        if (!this.expandedKey || this.lines.length === 0) {
          return;
        }
        if (to.query.page > (from.query.page || 1)) {
          this.expandedKey = this.lines[0][this.config.lineKey];
        } else if ((to.query.page || 1) < from.query.page) {
          this.expandedKey = this.lines[this.lines.length - 1][this.config.lineKey];
        }
      });
    },
  },
  created() {
    this.fetchReportLines();
  },
  deactivated() {
    this.removeKeyListeners();
  },
  methods: {
    addKeyListeners() {
      window.addEventListener('keydown', this.onKeyDown);
    },
    removeKeyListeners() {
      window.removeEventListener('keydown', this.onKeyDown);
    },
    onKeyDown(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.next();
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.prev();
      }
    },
    customSelect() {
      if (
        this.config.customSelectEnabled &&
        this.config.customSelectEnabled(this.$route.params.state)
      ) {
        return this.config.customSelect;
      }
      return undefined;
    },
    async onCustomSelectChanged(value) {
      if (value) {
        await this.fetchReportLines(this.config.customSelectFilter);
      } else {
        await this.fetchReportLines();
      }
    },
    async bulkAction(value) {
      switch (value) {
        case 'identify':
          await this.identifyMany(this.selected);
          break;
        case 'unidentify':
          await this.revertMatchMany(this.selected);
          break;
        case 'newmany':
          await this.handleNewMany(this.selected);
          break;
        case 'newall':
          await this.handleNewAll();
          break;
      }
    },
    async handleNewMany(ids) {
      this.loading = true;
      await mutate({
        mutation: this.config.gql.handleNew.mutate,
        variables: { command: { report_id: this.$route.params.id, ids } },
      });
      this.refreshLines(ids);
      this.loading = false;
    },
    async handleNewAll() {
      this.loading = true;
      const { processId } = await mutate(
        {
          mutation: this.config.gql.handleNew.mutate,
          variables: { command: { report_id: this.$route.params.id, all: true } },
        },
        { pickProp: this.config.gql.handleNew.pickProp },
      );
      this.$store.dispatch('process/addProcess', {
        name: `Unmatched ${this.reportName}`,
        id: processId,
      });
      this.itemCount = 0;
      this.lines = [];
      this.$emit('refreshHeader');
      this.loading = false;
    },
    async handleNew(line) {
      this.loading = true;
      await mutate({
        mutation: this.config.gql.handleNew.mutate,
        variables: { command: { report_id: this.$route.params.id, ids: [line.id] } },
      });
      this.next();
      await this.refreshSingleLine(line[this.config.lineKey]);
      this.loading = false;
    },
    async identify(line, candidateId, changes) {
      this.loading = true;
      await mutate({
        mutation: this.config.gql.identify.mutate,
        variables: { command: { id: line[this.config.lineKey], match_id: candidateId } },
      });
      if (!_.isEmpty(changes)) {
        const agg = this.config.hitToAggregate({ id: candidateId, ...changes });
        await this.save(agg);
      }
      await this.refreshSingleLine(line[this.config.lineKey]);
      this.next();
      this.loading = false;
    },
    async identifyMany(ids) {
      this.loading = true;
      await mutate({
        mutation: this.config.gql.identifyMany.mutate,
        variables: { command: { ids } },
      });
      this.refreshLines(ids);
      this.loading = false;
    },
    async revertMatch(line) {
      this.loading = true;
      await mutate({
        mutation: this.config.gql.unidentify.mutate,
        variables: { command: { id: line.id } },
      });
      this.next();
      await this.refreshSingleLine(line[this.config.lineKey]);
      this.loading = false;
    },
    async revertMatchMany(ids) {
      this.loading = true;
      await mutate({
        mutation: this.config.gql.unidentifyMany.mutate,
        variables: { command: { ids } },
      });
      this.refreshLines(ids);
      this.loading = false;
    },
    isLastLineOnPage(line) {
      return !!line && line === this.lines[this.lines.length - 1];
    },
    isFirstLineOnPage(line) {
      return !!line && line === this.lines[0];
    },
    next() {
      const cursor = this.getCursor();
      if (cursor.next) {
        this.expandedKey = cursor.next[this.config.lineKey];
      } else if (this.isLastLineOnPage(cursor.current) && this.numberOfPages() > this.page) {
        this.selectPage(this.page + 1);
      }
    },
    prev() {
      const cursor = this.getCursor();
      if (cursor.prev) {
        this.expandedKey = cursor.prev[this.config.lineKey];
      } else if (this.isFirstLineOnPage(cursor.current) && this.page > 1) {
        this.selectPage(this.page - 1);
      }
    },
    getCursor(line = undefined) {
      const expandedKey = line ? line[this.config.lineKey] : this.expandedKey;
      const curIdx =
        expandedKey && this.lines.findIndex((l) => l[this.config.lineKey] === expandedKey);
      return {
        prev: this.lines[curIdx - 1],
        current: this.lines[curIdx],
        next: this.lines[curIdx + 1],
      };
    },
    selectNext(line) {
      const cursor = this.getCursor(line);
      const gotoLine = cursor.next || cursor.prev;
      this.expandedKey = gotoLine ? gotoLine[this.config.lineKey] : undefined;
    },
    focusCurrentLine() {
      const components = this.$refs[this.expandedKey];
      if (components && components.length === 1) {
        components[0].$el.scrollIntoView({ block: 'start' });
      }
    },
    refreshLines(ids) {
      this.lines = this.lines.filter((l) => !ids.includes(l[this.config.lineKey]));
      this.selected = this.selected.filter((id) => !ids.includes(id));
      this.itemCount -= ids.length;
      this.$emit('refreshHeader');
    },
    async refreshSingleLine(id) {
      const idx = this.lines.findIndex((l) => l[this.config.lineKey] === id);
      const newline = await this.fetchSingleLine(id);
      this.lines = [...this.lines.slice(0, idx), newline, ...this.lines.slice(idx + 1)];
      this.selected = this.selected.filter((selectedId) => selectedId !== id);
      this.itemCount -= 1;
      this.$emit('refreshHeader');
    },
    async save(aggregate) {
      await this.config.saveAggregate(aggregate);
    },
    async fetchReportLines(filter = {}) {
      try {
        this.removeKeyListeners();
        this.loading = true;
        const response = await query(
          {
            query: this.config.gql.lines.query,
            variables: {
              request: {
                report_id: this.$route.params.id,
                match_state: this.line.state,
                limit: this.pageSize,
                offset: this.getOffset(),
                ascending: this.sortOrder && this.sortOrder === 'asc',
                order_by: this.sortParam,
                main_artist_range: this.rangeStart && {
                  start: this.rangeStart,
                  end: this.rangeEnd,
                },
                ...filter,
              },
            },
          },
          { pickProp: this.config.gql.lines.pickProp },
        );
        this.itemCount = response.total_count;
        this.lines = response.items;
      } catch (err) {
        this.itemCount = 0;
        this.lines = [];
        this.$addStarError({ title: 'Failed to get report lines', ...err });
        console.error(err);
      } finally {
        this.loading = false;
        this.addKeyListeners();
      }
    },
    async fetchSingleLine(id) {
      try {
        this.loading = true;
        const response = await query(
          {
            query: this.config.gql.singleLine.query,
            variables: {
              request: {
                report_id: this.$route.params.id,
                line_id: id,
              },
            },
          },
          { pickProp: this.config.gql.singleLine.pickProp },
        );
        return response;
      } catch (err) {
        this.$addStarError({ title: 'Failed to get report lines', ...err });
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    toggleSelect(key) {
      if (this.selected.includes(key)) {
        this.selected = this.selected.filter((k) => k !== key);
      } else {
        this.selected = [...this.selected, key];
      }
    },
    toggleSelectAll(selectAll) {
      if (selectAll) {
        this.selected = this.lines.map((l) => l[this.config.lineKey]);
      } else {
        this.selected = [];
      }
    },
  },
};
</script>
