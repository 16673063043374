import gql from 'graphql-tag';
import SearchHelper from '@/components/search/searchHelper';
import Vue from 'vue';
import PnrService from '@/services/pnrService';
import { createPatchBasicInfoCommand } from '@/domain/recordingDomain';

export default {
  'supplementary-remuneration': {
    name: 'Supplementary Remuneration',
    entityType: 'recording',
    lineTitleKey: 'recording_title',
    candidateRoute: '/recordings/',
    processType: 'SUPPREM',
    reportType: 'SRR',
    reportDate: true,
    selectChannel: true,
    reportTransferButtonText: 'Transfer Supp Rem to Distribution',
    templateFile: {
      url: '/star_asr_template.xltx',
      text: 'Download ASR CSV template for Excel',
    },
    transferEnabled: (header) => {
      return (
        header &&
        header.srr_match_data &&
        header.srr_match_data.line_count === header.srr_match_data.matched_lines
      );
    },
    customSelectEnabled: (state) => {
      return state === 'matched';
    },
    customSelect: {
      name: 'Filter',
      selected: undefined,
      items: [{ key: 'issues', name: 'Issues' }],
      itemKey: 'key',
      itemValue: 'name',
    },
    customSelectFilter: {
      with_issues: true,
    },
    buildIssueHtml: (issues) => {
      let html = '<ul>';
      for (const issueCode of issues) {
        const text = Vue.$i18n.t(`suppRem.issueCodes.${issueCode}`);
        html += `<li>${text}</li>`;
      }
      html += '</ul>';
      return html;
    },
    sources: [
      {
        name: 'supprem',
        localCode: 'SR',
        fileType: 'csv',
        fileEncoding: 'UTF-8',
        contentType: 'text/csv',
      },
    ],
    lines: [
      {
        name: 'Matched',
        key: 'matched_lines',
        state: 'M',
        color: 'green',
        bulkActions: [{ key: 'unidentify', value: 'Unidentify' }],
      },
      {
        name: 'Candidates',
        key: 'candidate_lines',
        state: 'C',
        color: 'gold',
        bulkActions: [{ key: 'identify', value: 'Identify' }],
      },
      {
        name: 'Unmatched',
        key: 'rested_lines',
        state: 'R',
        color: 'orange',
        bulkActions: [
          { key: 'newmany', value: 'New recordings' },
          { key: 'newall', value: 'New recordings (ALL)' },
        ],
      },
      {
        name: 'Transferred',
        key: 'transferred_lines',
        state: 'T',
        color: 'blue',
        disableExpand: true,
      },
    ],
    lineKey: 'id',
    columns: [
      {
        name: 'Recording',
        key: 'recording_title',
        sortParam: 'recording_title',
        ascending: true,
        active: true,
        default: true,
      },
      {
        name: 'Main Artist',
        key: 'main_artist',
        sortParam: 'main_artist',
        ascending: true,
        active: false,
        default: false,
      },
      {
        name: 'Album',
        key: 'album_titles',
        filter: 'prettyPrintArray',
      },
      { name: 'ISRC', key: 'isrc', sortParam: 'isrc', ascending: true },
      {
        name: 'Recording Country',
        key: 'recording_country',
        filter: 'formatCountry',
        sortParam: 'recording_country',
        ascending: true,
      },
      { name: 'Release year', key: 'release_year', sortParam: 'release_year', ascending: true },
      { name: 'Label', key: 'label', sortParam: 'label', ascending: true },
      { name: 'Match Type', key: 'match_method', sortParam: 'match_method', ascending: true },
      {
        name: 'Gross Amount',
        key: 'gross_amount',
        sortParam: 'gross_amount',
        ascending: true,
        count: true,
        thinColumn: true,
        styleClass: 'text--right',
        filter: ['formatAmountThousandSeparator', 'SEK'],
      },
      {
        name: 'Net Amount',
        key: 'net_amount',
        sortParam: 'net_amount',
        ascending: true,
        count: true,
        thinColumn: true,
        styleClass: 'text--right',
        filter: ['formatAmountThousandSeparator', 'SEK'],
      },
    ],
    compare: [
      {
        candidate: {
          displayKey: 'basic_info.main_artist.name',
          displayFilter: 'prettyPrintArray',
          valueKey: 'main_artist',
        },
        incoming: {
          displayKey: 'basic_info.main_artist.name',
          displayFilter: 'toTitleCase',
          valueKey: 'main_artist',
        },
        allowAction: false,
      },
      {
        candidate: {
          displayKey: 'basic_info.name',
          displayFilter: 'prettyPrintArray',
          valueKey: 'name',
          value: (candidate) =>
            candidate.version_title
              ? `${candidate.name} (${candidate.version_title})`
              : candidate.name,
        },
        incoming: {
          displayKey: 'basic_info.name',
          displayFilter: 'toTitleCase',
          valueKey: 'recording_title',
        },
        allowAction: false,
      },
      {
        candidate: {
          displayKey: 'albums',
          displayFilter: 'prettyPrintAlbumArray',
          valueKey: 'albums',
        },
        incoming: {
          displayKey: 'albums',
          displayFilter: 'prettyPrintArray',
          valueKey: 'album_titles',
        },
        allowAction: false,
        enabled: false,
      },
      {
        candidate: {
          displayKey: 'basic_info.isrc',
          displayFilter: 'prettyPrintArray',
          valueKey: 'isrc',
        },
        incoming: {
          displayKey: 'basic_info.isrc',
          valueKey: 'isrc',
        },
        allowAction: true,
        inheritWhenEmpty: true,
      },
      {
        candidate: {
          displayKey: 'basic_info.release_date',
          valueKey: 'release_date',
          displayFilter: 'formatDate',
        },
        incoming: {
          displayKey: 'basic_info.release_date',
          valueKey: 'release_year',
          displayFilter: 'formatDate',
        },
        comparator: 'yearsMatch',
        allowAction: true,
      },
      {
        candidate: {
          displayKey: 'basic_info.recording_date',
          valueKey: 'recording_date',
        },
        incoming: {
          displayKey: 'basic_info.recording_date',
          valueKey: 'recording_date',
          displayFilter: 'formatDate',
        },
        comparator: 'yearsMatch',
        allowAction: true,
      },
      {
        candidate: {
          displayKey: 'basic_info.recorded_in_country',
          displayFilter: 'formatCountry',
          valueKey: 'recorded_in_country',
        },
        incoming: {
          displayKey: 'basic_info.recorded_in_country',
          displayFilter: 'formatCountry',
          valueKey: 'recording_country',
        },
        allowAction: true,
      },
    ],
    candidateDateKey: 'recording_date',
    searchAndCompare: {
      title: 'Search recording:',
      template: 'recording',
      searcher: (terms) => SearchHelper.quickSearchRecording(terms),
      mapper: (result) => ({
        id: result.id,
        lineup_locked: result.lineup_locked,
        name: result.name,
        label: '',
        main_artist: result.main_artist.name,
        recording_date: result.recording_date,
        isrc: result.isrc,
        release_date: result.release_date,
        recorded_in_country: result.recorded_in_country,
      }),
    },
    hitToAggregate: (hit) => {
      return {
        id: hit.id,
        lineup_locked: hit.lineup_locked,
        basic_info: {
          name: hit.name,
          isrc: hit.isrc,
          release_date: hit.release_date,
          recorded_in_country: hit.recorded_in_country,
        },
      };
    },
    saveAggregate: async (aggregate) => {
      const patchBasicInfoCommand = PnrService.prepareCommand(
        {
          id: aggregate.id,
        },
        createPatchBasicInfoCommand(),
        { basic_info: aggregate.basic_info },
      );
      console.log('sending this command', patchBasicInfoCommand);
      await PnrService.postCommands({ errorTitle: 'Could not patch recording basic info' }, [
        patchBasicInfoCommand,
      ]);
    },
    gql: {
      delete: {
        mutate: gql`
          mutation deleteSuppRemReport($reportId: String!) {
            deleteSuppRemReport(reportId: $reportId)
          }
        `,
      },
      transfer: {
        mutate: gql`
          mutation transferToDistribution($command: TransferSuppRemLinesCommand) {
            transferToDistribution(command: $command)
          }
        `,
      },
      handleNew: {
        mutate: gql`
          mutation createRestedSuppRemRecordings($command: CreateRestedSuppRemCommand) {
            createRestedSuppRemRecordings(command: $command) {
              processId
            }
          }
        `,
        pickProp: 'createRestedSuppRemRecordings',
      },
      identify: {
        mutate: gql`
          mutation identifySuppRemLine($command: IdentifySuppRemCommand) {
            identifySuppRemLine(command: $command)
          }
        `,
      },
      identifyMany: {
        mutate: gql`
          mutation identifyManySuppRemLines($command: IdentifyManySuppRemCommand) {
            identifyManySuppRemLines(command: $command)
          }
        `,
      },
      unidentify: {
        mutate: gql`
          mutation unidentifySuppRemLine($command: UnidentifySuppRemCommand) {
            unidentifySuppRemLine(command: $command)
          }
        `,
      },
      unidentifyMany: {
        mutate: gql`
          mutation unidentifyManySuppRemLines($command: UnidentifyManySuppRemCommand) {
            unidentifyManySuppRemLines(command: $command)
          }
        `,
      },
      lines: {
        query: gql`
          query ($request: SuppRemLinesRequest) {
            getSuppRemLines(request: $request) {
              total_count
              items {
                id
                source
                revenue_year
                main_artist
                recording_title
                album_titles
                isrc
                label
                release_year
                recording_country
                gross_amount
                net_amount
                info
                match_id
                match_state
                match_method
                hits
                issues
              }
            }
          }
        `,
        pickProp: 'getSuppRemLines',
      },
      singleLine: {
        query: gql`
          query ($request: SingleSuppRemLineRequest) {
            getSuppRemSingleLine(request: $request) {
              id
              source
              revenue_year
              main_artist
              recording_title
              album_titles
              isrc
              label
              release_year
              recording_country
              gross_amount
              net_amount
              info
              match_id
              match_state
              match_method
              hits
              issues
            }
          }
        `,
        pickProp: 'getSuppRemSingleLine',
      },
      hits: {
        query: gql`
          query loadHits($streamIds: [String]) {
            loadHits(streamIds: $streamIds) {
              id
              name
              version_title
              main_artist
              label
              recording_date
              status
              samiLineupMember
              vrdb2_id
              isrc
              recording_date
              release_date
              recorded_in_country
              alternate_titles
              lineup_length
              lineup_locked
              albums {
                id
                name
              }
            }
          }
        `,
        pickProp: 'loadHits',
      },
    },
  },
  // Todo: config for other types of reports
  // mediarc: {
  //   processType: 'MEDIARC',
  //   reportType: 'MAR',
  //   sources: [
  //     {
  //       name: 'mediarc',
  //       localCode: 'SR',
  //       fileType: 'xml',
  //       fileEncoding: 'UTF-8',
  //       contentType: 'text/xml',
  //     },
  //   ],
  //   lines: [
  //     { name: 'Matched', key: 'matched_lines', state: 'M', color: 'green' },
  //     { name: 'Candidates', key: 'candidate_lines', state: 'C', color: 'gold' },
  //     { name: 'Unmatched', key: 'rested_lines', state: 'R', color: 'orange' },
  //     { name: 'Done', key: 'done_lines', state: 'D', color: 'blue' },
  //   ],
  // },
  playtime: {
    name: 'Music Report',
    processType: 'PLAYTIME',
    reportType: 'PRR',
    reportDate: true,
    localCode: true,
    sources: [
      {
        name: 'ur',
        localCode: 'SR',
        fileType: 'txt',
        fileEncoding: 'ISO-8859-1',
        contentType: 'text/plain',
      },
      {
        name: 'sr',
        localCode: 'SR',
        fileType: 'txt',
        fileEncoding: 'ISO-8859-1',
        contentType: 'text/plain',
      },
      {
        name: 'com',
        localCode: 'SR',
        fileType: 'txt',
        fileEncoding: 'ISO-8859-1',
        contentType: 'text/plain',
      },
      { name: 'csv', fileType: 'csv', fileEncoding: 'UTF-8', contentType: 'text/plain' },
      { name: 'dbf', localCode: 'LIBRARY', fileType: 'dbf', contentType: 'text/plain' },
    ],
    lines: [
      { name: 'Matched', key: 'matched_lines', state: 'M', color: 'green' },
      { name: 'Candidates', key: 'candidate_lines', state: 'C', color: 'gold' },
      { name: 'Unmatched', key: 'rested_lines', state: 'R', color: 'orange' },
      { name: 'Transferred', key: 'transferred_lines', state: 'T', color: 'blue' },
      { name: 'Error', key: 'error_lines', state: 'E', color: 'grey' },
    ],
  },
};
