<template>
  <div class="row pagination flush--ends">
    <div>
      <div :class="rangeValid ? 'pagination__range' : 'pagination__range text--error'">
        Showing {{ range.first }} - {{ range.last }} of
        {{ $filters.formatNumber(numberOfHits) }}
      </div>
      <div v-if="!rangeValid" class="mandatory">
        The maximum search result is {{ $filters.formatNumber(SEARCH_RESULT_LIMIT) }}
      </div>
    </div>

    <div v-show="numberOfPages > 1" class="pagination__numbers">
      <ul>
        <li :class="{ 'waves-effect': true, disabled: selectedPage === 1 || loading }">
          <a href="#!" @click.prevent="selectNumber(1)"><i class="fas fa-angle-double-left" /></a>
        </li>

        <li :class="{ 'waves-effect': true, disabled: selectedPage === 1 || loading }">
          <a href="#!" @click.prevent="selectPrev()"><i class="fas fa-angle-left" /></a>
        </li>

        <li class="waves-effect">
          <input v-model="page" @keyup.enter="validateInput" />
        </li>

        <li class="waves-effect">/ {{ numberOfPages }}</li>

        <li :class="{ 'waves-effect': true, disabled: selectedPage === numberOfPages || loading }">
          <a href="#!" @click.prevent="selectNext()"><i class="fas fa-angle-right" /></a>
        </li>

        <li :class="{ 'waves-effect': true, disabled: selectedPage === numberOfPages || loading }">
          <a href="#!" @click.prevent="selectNumber(numberOfPages)">
            <i class="fas fa-angle-double-right" />
          </a>
        </li>
      </ul>
    </div>
    <div class="pagination__selector">
      <template v-if="showHitsPerPage">
        <label class="label">Results per page</label>
        <select
          name="hitsPerPage"
          :value="hitsPerPage"
          :disabled="loading"
          @change="updateHitsPerPage($event.target.value)"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>1000</option>
        </select>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    numberOfPages: Number,
    selectedPage: Number,
    numberOfHits: Number,
    hitsPerPage: Number,
    showHitsPerPage: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    topic: { type: String, required: false },
    rangeLimit: { type: Boolean, default: false },
  },
  data() {
    return {
      page: '1',
      range: {
        first: 1,
        last: '',
      },
      SEARCH_RESULT_LIMIT: 10000,
      rangeValid: true,
    };
  },
  watch: {
    numberOfHits() {
      this.calculateRange(this.selectedPage, this.hitsPerPage);
    },
    selectedPage(val) {
      this.page = `${val}`;
    },
  },
  created() {
    this.page = this.selectedPage;
    this.calculateRange(this.selectedPage, this.hitsPerPage);
    this.addKeyListeners();

    if (this.topic != null) {
      const hitsPerPage = Number(localStorage.getItem(`pagination:${this.topic}:hits-per-page`));
      if (hitsPerPage > 0 && this.hitsPerPage != hitsPerPage) {
        this.$emit('updateHitsPerPage', hitsPerPage);
      }
    }
  },
  destroyed() {
    this.removeKeyListeners();
  },
  methods: {
    addKeyListeners() {
      window.addEventListener('keydown', this.onKeyDown);
    },
    removeKeyListeners() {
      window.removeEventListener('keydown', this.onKeyDown);
    },
    onKeyDown(e) {
      if (e.target.nodeName !== 'INPUT') {
        if (e.key === 'ArrowRight') {
          e.preventDefault();
          window.scrollTo(0, 56);
          this.selectNext();
        }
        if (e.key === 'ArrowLeft') {
          e.preventDefault();
          window.scrollTo(0, 56);
          this.selectPrev();
        }
      }
    },
    validateInput() {
      if (/^[0-9]+$/.test(this.page) && +this.page >= 1 && +this.page <= this.numberOfPages) {
        this.selectNumber(+this.page);
      }
    },
    calculateRange(selectedPage, hitsPerPage) {
      this.range.first = this.numberOfHits > 0 ? hitsPerPage * (selectedPage - 1) + 1 : 0;
      this.range.last =
        this.numberOfHits < hitsPerPage * selectedPage
          ? this.numberOfHits
          : hitsPerPage * selectedPage;
      if (this.rangeLimit) {
        this.rangeValid =
          this.range.first <= this.SEARCH_RESULT_LIMIT &&
          this.range.last <= this.SEARCH_RESULT_LIMIT;
      }
    },
    selectNumber(selectedPage) {
      this.calculateRange(selectedPage, this.hitsPerPage);
      if (this.rangeValid) {
        this.$emit('selectPage', selectedPage);
      }
    },
    selectPrev() {
      if (this.selectedPage !== 1) {
        this.calculateRange(this.selectedPage - 1, this.hitsPerPage);
        this.$emit('selectPage', this.selectedPage - 1);
      }
    },
    selectNext() {
      if (this.selectedPage !== this.numberOfPages) {
        this.calculateRange(this.selectedPage + 1, this.hitsPerPage);
        if (this.rangeValid) {
          this.$emit('selectPage', this.selectedPage + 1);
        }
      }
    },
    updateHitsPerPage(hitsPerPage) {
      if (this.rangeValid) {
        this.$emit('updateHitsPerPage', hitsPerPage);
      }
      this.calculateRange(1, hitsPerPage);

      if (this.topic != null) {
        localStorage.setItem(`pagination:${this.topic}:hits-per-page`, hitsPerPage);
      }
    },
  },
};
</script>
