import gql from 'graphql-tag';

const getDuplicates = gql`
  query getDuplicates($request: DuplicatesRequest!) {
    getDuplicates(request: $request) {
      total
      items {
        stream_id
        type
        payload
        detected
        tags
        nr_duplicates
      }
    }
  }
`;

const getAlbumMatches = gql`
  query getAlbumMatches($id: String!) {
    getAlbumMatches(id: $id) {
      stream_id
      matches {
        id
        name
        main_artist {
          name
          id
        }
        label
        catalog_number
        number_of_tracks
        release_date
      }
    }
  }
`;

const getMainartistMatches = gql`
  query getMainartistMatches($id: String!) {
    getMainartistMatches(id: $id) {
      stream_id
      matches {
        id
        name
        alternate_names
        origin_country
        performers {
          name
          id
        }
        num_performers
        albums {
          total
          albums {
            id
            name
          }
        }
        recordings {
          total
          recordings {
            id
            name
          }
        }
      }
    }
  }
`;

const getMainartistMergePreview = gql`
  query getMainartistMergePreview($id: String) {
    getMainartistMergePreview(id: $id) {
      id
      master
      version
      basic_info {
        name
        genre
        alternate_names
        active_period {
          to
          from
        }
        origin_country
        origin_city
        local_codes {
          code
          value
        }
        tags
        profile_description
      }
      performers {
        id
        name
        mandates {
          society_code
          type
        }
        instrument
        role
        nationality
        ipn
        pseudo_name
        active_period {
          from
          to
        }
      }
      albums {
        total
        albums {
          id
          name
          label
          catalog_number
          release_date
        }
      }
      recordings {
        total
        recordings {
          id
          name
          version_title
          duration_sec
          isrc
        }
      }
    }
  }
`;

const getAlbumMergePreview = gql`
  query getAlbumMergePreview($id: String) {
    getAlbumMergePreview(id: $id) {
      id
      master
      version
      basic_info {
        name
        main_artist {
          id
          name
          type
        }
        catalog_number
        release_date
        composer
        label
        local_codes {
          code
          value
        }
        tags
        description
      }
      tracks {
        name
        track_title
        duration_sec
        status
        isrc
        version_title
        main_artist {
          id
          name
        }
        recording {
          id
          side_no
          track_no
        }
      }
    }
  }
`;

export default {
  getDuplicates,
  getAlbumMatches,
  getMainartistMatches,
  getAlbumMergePreview,
  getMainartistMergePreview,
};
