<template>
  <div>
    <Spinner v-if="loading" />

    <div class="row wrapper">
      <div class="col s8">
        <h1>Results for {{ header.report_name }}</h1>
      </div>
      <div class="col s4">
        <button class="btn float-right" :disabled="!transferEnabled()" @click="transfer()">
          <i class="fas fa-sync-alt" :class="{ spin: false }"></i>
          {{ config.reportTransferButtonText }}
        </button>
      </div>
    </div>

    <div v-if="header.occupied" class="row wrapper">
      <div class="col s6">
        <ProcessProgressBar
          :id="header.processId"
          :display-msg="true"
          :display-name="true"
          @finished="fetchReportHeader"
        />
      </div>
    </div>

    <div class="row wrapper">
      <div class="tabs">
        <div class="tabs__wrapper">
          <router-link
            v-for="line of config.lines"
            :key="line.key"
            :to="line.name.toLowerCase()"
            class="nowrap"
          >
            {{ line.name }} ({{ (header[matchDataKey] && header[matchDataKey][line.key]) || 0 }})
          </router-link>
        </div>
      </div>
    </div>

    <router-view
      ref="routerview"
      :report-name="header.report_name"
      @refreshHeader="fetchReportHeader"
    />
  </div>
</template>

<script>
import MatchingService from '../../services/matchingService';
import Spinner from '../spinner';
import config from './config';
import * as uuid from 'uuid';
import { mutate } from '@/services/apolloRequest';
import AuthenticationService from '@/services/authenticationService';
import ProcessProgressBar from '@/components/process/process-progress-bar.vue';

export default {
  name: 'MusicReport',
  components: {
    Spinner,
    ProcessProgressBar,
  },
  data() {
    return {
      loading: false,
      header: {},
    };
  },
  computed: {
    config() {
      return config[this.$route.params.type];
    },
    matchDataKey() {
      return `${this.config.reportType.toLowerCase()}_match_data`;
    },
  },
  created() {
    this.fetchReportHeader();
  },
  methods: {
    async transfer() {
      const processId = uuid.v4();
      try {
        this.$store.dispatch('process/addProcess', {
          name: `Transferring to ${this.header.report_name} to distribution`,
          id: processId,
        });
        await mutate({
          mutation: this.config.gql.transfer.mutate,
          variables: {
            command: {
              report_id: this.$route.params.id,
              process_id: processId,
              username: AuthenticationService.getUserName(),
            },
          },
        });
      } catch (error) {
        error.title = 'Failed to transfer to distribution!';
        this.$addStarError(error);
      }
    },
    transferEnabled() {
      return this.config.transferEnabled(this.header);
    },
    async fetchReportHeader() {
      try {
        this.loading = true;
        this.header = await MatchingService.getReportHeader(
          this.config.reportType,
          this.$route.params.id,
        );
        if (this.header.processId) {
          const process = this.getProcess(this.header.processId);
          if (!process) {
            this.$store.dispatch('process/addProcess', {
              name: `Payment ${this.header.report_name}`,
              id: this.header.processId,
            });
          }
        }
      } catch (err) {
        this.$addStarError({ title: 'Failed to get report header', ...err });
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    getProcess(id) {
      return this.$store.getters['process/process'](id);
    },
  },
};
</script>
