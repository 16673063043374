<template>
  <div>
    <strong v-if="displayName">{{ process.name }}</strong>
    <div v-if="displayMsg">{{ lastRow.message }}</div>
    <progress-bar :total="progress.total" :processed="progress.processed" :show-percent="true" />
  </div>
</template>

<script>
import { STATE } from '../../store/modules/process/store';
import ProgressBar from '../ui/progress-bar.vue';

export default {
  name: 'ProcessProgressBar',
  components: {
    ProgressBar,
  },
  props: {
    id: String,
    displayMsg: {
      type: Boolean,
      default: false,
    },
    displayName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentState: null,
    };
  },
  computed: {
    process() {
      return this.$store.getters['process/process'](this.id) || {};
    },
    lastRow() {
      const { logRows = [] } = this.process;
      return logRows[logRows.length - 1] || {};
    },
    progress() {
      this.checkStateChange();
      const { progress = {} } = this.lastRow;
      return {
        total: progress.total || 0,
        processed: progress.processed || 0,
        entity: progress.entity || null,
      };
    },
  },
  watch: {
    lastRow: {
      deep: true,
      handler(val) {
        if (val.progress) {
          const progress = val.progress.total > 0 ? val.progress.processed / val.progress.total : 0;
          this.$emit('progressChanged', progress);
        }
      },
    },
  },
  created() {
    this.currentState = this.process.state;
  },
  methods: {
    checkStateChange() {
      if (this.process.state !== this.currentState) {
        switch (this.process.state) {
          case STATE.FINISHED:
            this.$emit('finished');
            break;
        }
        this.currentState = this.process.state;
      }
    },
  },
};
</script>
