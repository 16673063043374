<template>
  <div v-if="visible" class="notification">
    <div class="dismiss" @click="dismiss">×</div>
    <p>New version available!</p>
    <button @click="forceRefresh">Refresh Star Now</button>
  </div>
</template>

<script>
import { version as CURRENT_VERSION } from '../../../package.json';

export default {
  name: 'VersionRefresh',
  data() {
    return {
      timeout: 60 * 1000, // 1 minute
      interval: null,
      visible: false,
    };
  },
  mounted() {
    const domain = window.location.hostname.split('.')[0];
    if (domain !== 'localhost') {
      this.checkVersion();
      this.interval = setInterval(this.checkVersion, this.timeout);
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    dismiss() {
      this.visible = false;
    },
    forceRefresh() {
      window.location.reload();
    },
    async checkVersion() {
      if (this.visible) {
        // don't need to fetch if we already prompt user to refresh
        return;
      }
      try {
        const response = await fetch('/package.json');
        const data = await response.json();
        const version = data.version;
        if (CURRENT_VERSION !== version) {
          this.visible = true;
        }
      } catch (error) {
        console.error('error when checking for version changes', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes smooth-appear {
  to {
    bottom: 20px;
    opacity: 70%;
  }
}

.notification {
  position: fixed;
  bottom: -100px;
  right: 20px;
  background-color: var(--green--light);
  color: var(--grey--dark);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: smooth-appear 1s ease forwards;
}
.notification:hover {
  opacity: 100% !important;
}
.notification p {
  margin: 0;
}

button {
  background-color: var(--grey--dark);
  color: white;
  border: none;
  opacity: 80%;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: var(--grey--darkest);
  opacity: 100%;
}

.dismiss {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--grey--dark);
  color: white;
  opacity: 50%;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dismiss:hover {
  background-color: var(--grey--darkest);
  opacity: 100%;
}
</style>
