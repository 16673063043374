<template>
  <div class="report__headers">
    <upload-sdeg-file :source="$router.currentRoute.name" @fetchData="fetchData" />

    <div class="row">
      <div class="col s2 float-right report__headers-refresh">
        <refresh-button :is-loading="loading" @click="fetchData" />
      </div>
    </div>

    <div
      v-if="
        $router.currentRoute.name === 'sdegPlayedRecordingsOverview' ||
        $router.currentRoute.name === 'sdegClaimsOverview'
      "
      class="row filter-row"
    >
      <div class="col s3 float-right">
        <filter-input
          :filter-query="filterQuery"
          :placeholder="
            $router.currentRoute.name === 'sdegPlayedRecordingsOverview'
              ? 'E.g. Played recordings file from PPL'
              : 'E.g. Claims from PPL'
          "
          @input="debouncedSearch"
        />
      </div>
      <div class="col s2 float-right">
        <select-society
          name="society-filter"
          label="Society"
          :value="societyIpdCodeFilter"
          @input="onSocietyFilter"
        />
      </div>
      <div v-if="societyIpdCodeFilter" class="col s2 float-right push--top">
        <input
          id="finished-processes"
          name="finished-processes"
          type="checkbox"
          class="filled-in"
          :checked="finished"
          @input="onFinished($event.target.checked)"
        />
        <label for="finished-processes">Include finished processes</label>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <table class="report__headers-table">
          <sortable-head
            :columns="tableColumns"
            :bulk-enabled="false"
            :default-sort-should-override="true"
            :more-options="false"
            @sort="onSort"
          />
          <tbody>
            <template v-if="loading">
              <tr>
                <td colspan="12">
                  <component-spinner />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-if="societyIpdCodeFilter && reportHeadersResult.items.length === 0">
                <td class="none" colspan="12">
                  No report files for {{ $filters.formatSociety(societyIpdCodeFilter) }}
                </td>
              </tr>
              <tr
                v-for="(reportHeader, i) in reportHeadersResult.items"
                :key="reportHeader.report_id"
              >
                <td v-if="enablePrioritization">
                  <SelectPriority
                    :disable-increment="
                      !sortedByPriority || i === reportHeadersResult.items.length - 1
                    "
                    :disable-decrement="!sortedByPriority || i === 0"
                    :value="reportHeader.priority || 0"
                    @input="updateReportPriority(i, $event)"
                  />
                </td>
                <td>
                  <div>
                    {{ $filters.formatDate(reportHeader.date_created) }}
                  </div>
                </td>
                <td v-if="reportHeader.report_state === 'E'">
                  <div>
                    {{ reportHeader.report_name }}
                  </div>
                  <div>
                    <div>
                      <i>{{ reportHeader.error_message }}</i>
                    </div>
                  </div>
                  <div v-if="reportHeader.file" class="add-extra">
                    <span>FILE: {{ reportHeader.file.filename || 'n/a' }}</span>
                    <span v-if="reportHeader.file.user">(by {{ reportHeader.file.user }})</span>
                  </div>
                </td>
                <td v-else-if="reportHeader.report_type === ReportType.PLAYLIST_REPORT">
                  <router-link
                    :to="{
                      path:
                        '/societies/' +
                        getSocietyId(reportHeader.local_code_id) +
                        '/sdeg/claims/' +
                        reportHeader.report_id +
                        '/to_check',
                      query: { worklist_id: undefined },
                    }"
                  >
                    {{ reportHeader.report_name }}
                  </router-link>
                  <div v-if="reportHeader.file" class="add-extra">
                    <span>FILE: {{ reportHeader.file.filename || 'n/a' }}</span>
                    <span v-if="reportHeader.file.user">(by {{ reportHeader.file.user }})</span>
                  </div>
                </td>
                <td v-else>
                  <router-link
                    :to="
                      '/societies/' +
                      getSocietyId(reportHeader.reporter_id) +
                      '/sdeg/played-recordings/' +
                      reportHeader.report_id +
                      '/matched'
                    "
                  >
                    {{ reportHeader.report_name }}
                  </router-link>
                  <div v-if="reportHeader.file" class="add-extra">
                    <span>FILE: {{ reportHeader.file.filename || 'n/a' }}</span>
                    <span v-if="reportHeader.file.user">(by {{ reportHeader.file.user }})</span>
                  </div>
                  <div
                    v-if="reportHeader.info && reportHeader.info.containsFakeIds === 'true'"
                    class="fake-ids"
                  >
                    Contains Fake LocalCodes
                  </div>
                </td>
                <td>
                  <span v-if="reportHeader.info">
                    {{ reportHeader.info.performance_year_from }} -
                    {{ reportHeader.info.performance_year_to }}
                  </span>
                  <span v-else class="none">Unknown</span>
                </td>
                <td>
                  <span v-if="reportHeader.report_type === ReportType.PLAYLIST_REPORT">
                    {{ $filters.formatSocietyCode(reportHeader.local_code_id) }}
                  </span>
                  <span v-else>
                    {{ $filters.formatSocietyCode(reportHeader.reporter_id) }}
                  </span>
                </td>

                <td>
                  <span
                    v-if="reportHeader.info && reportHeader.info.deadline"
                    :class="deadlinePassed(reportHeader.info) ? 'is-danger text--bold' : ''"
                  >
                    {{ reportHeader.info.deadline }}
                  </span>
                  <span v-else class="none">None</span>
                </td>

                <td style="min-width: 140px">
                  <span
                    class="chip float-left"
                    :class="getMatchStateClass(reportHeader.report_state)"
                    :title="reportHeader.error_message"
                  >
                    {{ $filters.formatMatchState(reportHeader.report_state) }}
                  </span>
                  <span
                    v-if="
                      isWaitState(reportHeader.report_state) &&
                      isWaitingOverdue(reportHeader.date_created)
                    "
                    class="alert-container"
                    title="Contact support. Not completed after 30 minutes of processing."
                  >
                    <i class="fas fa-stopwatch error"></i>
                  </span>
                  <div v-else class="small active alert-container">
                    <div class="spinner-layer spinner-green-only">
                      <div class="circle-clipper left">
                        <div class="circle"></div>
                      </div>
                      <div class="gap-patch">
                        <div class="circle"></div>
                      </div>
                      <div class="circle-clipper float-right">
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </td>
                <template v-if="reportHeader.report_type === ReportType.PLAYED_RECORDING_REPORT">
                  <td class="count count--grey">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.line_count) }}
                    </span>
                  </td>
                  <td class="count count--green">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.matched_lines) }}
                    </span>
                  </td>
                  <td class="count count--grey">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.ignored_lines) }}
                    </span>
                  </td>
                  <td class="count count--gold">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.candidate_lines) }}
                    </span>
                  </td>
                  <td class="count count--orange">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.rested_lines) }}
                    </span>
                  </td>
                  <td class="count count--blue">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.transferred_lines) }}
                    </span>
                  </td>
                  <td class="count count--grey">
                    <span>
                      {{ $filters.formatNumber(reportHeader.prr_match_data.error_lines) }}
                    </span>
                  </td>
                </template>
                <template v-if="reportHeader.report_type === ReportType.PLAYLIST_REPORT">
                  <td>
                    {{ $filters.formatDate(reportHeader.date_modified) }}
                  </td>
                  <td class="count count--grey">
                    <span>
                      {{ $filters.formatNumber(reportHeader.plr_match_data.line_count) }}
                    </span>
                    <span
                      v-if="
                        reportHeader.report_state === 'MF' &&
                        (reportHeader.plr_match_data.not_matched || 0) > 0
                      "
                      title="Unmatched claims"
                    >
                      <i class="fa fa-exclamation-circle text--red"></i>
                    </span>
                  </td>
                  <td class="count count--gold">
                    <span>
                      {{ $filters.formatNumber(reportHeader.plr_match_data.to_check) }}
                    </span>
                  </td>
                  <td class="count count--green">
                    <span>
                      {{ $filters.formatNumber(reportHeader.plr_match_data.accepted) }}
                    </span>
                  </td>
                  <td class="count count--orange">
                    <span>
                      {{ $filters.formatNumber(reportHeader.plr_match_data.rejected) }}
                    </span>
                  </td>
                  <td class="count count--blue">
                    <span>
                      {{ $filters.formatNumber(reportHeader.plr_match_data.applied) }}
                    </span>
                  </td>
                  <td class="count count--grey">
                    <span>
                      {{ $filters.formatNumber(reportHeader.plr_match_data.rejected_sent) }}
                    </span>
                  </td>
                </template>
                <td>
                  <context-menu
                    :options="['Download', 'Delete']"
                    :disabled-options="
                      (reportHeader.rejected || 0) +
                        (reportHeader.applied || 0) +
                        (reportHeader.rejected_sent || 0) +
                        (reportHeader.transferred_lines || 0) >
                      0
                        ? ['Delete']
                        : []
                    "
                    @delete="deleteReport(reportHeader)"
                    @download="downloadReport(reportHeader)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div class="report__headers-pagination">
          <pagination
            v-if="numberOfPages() > 0 && !loading"
            class="result__pagination"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="reportHeadersResult.total_count"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import ContextMenu from '../ui/context-menu';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import DownloadLink from '../ui/file/downloadLink';
import ReportHeadersMixin from '../../common/reportHeadersMixin';
import RemoteFileHelper from '../../common/remoteFileHelper';
import MatchingService from '../../services/matchingService';
import { ReportType, processType as ProcessType } from '../../domain/matchingDomain';
import FilterInput from '../ui/input/filter-input';
import SelectSociety from '../ui/select/select-society';
import Pagination from '../pagination';
import RefreshButton from '../ui/button/refresh-button';
import UploadSdegFile from './upload-sdeg-file';
import SortableHead from '../ui/table/sortable-head';
import ComponentSpinner from '../../components/component-spinner';
import SelectPriority from '@/components/ui/select/select-priority';

const playlistReportColumnMappings = [
  {
    name: 'Priority',
    ascending: true,
    sortParam: 'priority',
    active: true,
  },
  {
    name: 'Uploaded',
    ascending: false,
    sortParam: 'date_created',
  },
  {
    name: 'Name',
  },
  {
    name: 'Performance Year',
  },
  {
    name: 'Society',
  },
  {
    name: 'Deadline',
    ascending: false,
    sortParam: 'deadline',
    active: false,
  },
  {
    name: 'Status',
  },
  {
    name: 'Updated',
  },
  {
    name: 'Claims',
  },
  {
    name: 'To Check',
  },
  {
    name: 'Accepted',
  },
  {
    name: 'Rejected',
  },
  {
    name: 'Applied',
  },
  {
    name: 'Sent Rejections',
  },
];

const playedRecordingReportColumnMappings = [
  {
    name: 'Uploaded',
    sortParam: 'date_created',
  },
  {
    name: 'Name',
  },
  {
    name: 'Performance Year',
  },
  {
    name: 'Society',
  },
  {
    name: 'Deadline',
    ascending: false,
    sortParam: 'deadline',
    active: false,
  },
  {
    name: 'Status',
  },
  {
    name: 'Recordings',
  },
  {
    name: 'Matched',
  },
  {
    name: 'Matched not SAMI',
  },
  {
    name: 'Candidates',
  },
  {
    name: 'Unmatched',
  },
  {
    name: 'Sent Claims',
  },
  {
    name: 'Errors',
  },
];

export default {
  name: 'ViewReportHeaders',
  components: {
    ContextMenu,
    SortableHead,
    FilterInput,
    SelectSociety,
    Pagination,
    RefreshButton,
    UploadSdegFile,
    ComponentSpinner,
    SelectPriority,
  },
  mixins: [DebouncedSearchMixin(250), ReportHeadersMixin],
  props: {
    reportType: { type: String, required: true },
    enablePrioritization: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      ReportType,
      ProcessType,
      playlistReportColumnMappings,
      playedRecordingReportColumnMappings,
    };
  },
  computed: {
    ...mapGetters('society', ['metaInfoIpdSocietyCode']),
    societies() {
      return this.$store.state.appdata.societies;
    },
    tableColumns() {
      return this.reportType === ReportType.PLAYLIST_REPORT
        ? playlistReportColumnMappings
        : playedRecordingReportColumnMappings;
    },
    sortedByPriority() {
      return this.tableColumns[0].active;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.pagination.currentPage = 1;
        this.updateQueryParams();
      }
      this.fetchData();
    },
    metaInfoIpdSocietyCode() {
      if (this.$router.currentRoute.params.id) {
        this.fetchData();
      }
    },
  },
  created() {
    this.readQueryParams();
    this.sortTerms.param =
      this.sortTerms.param || this.tableColumns.find((c) => c.active).sortParam;
    this.sortTerms.order =
      this.sortTerms.param || this.tableColumns.find((c) => c.active).ascending ? 'asc' : 'desc';
    this.updateQueryParams();

    if (!this.$router.currentRoute.params.id || this.metaInfoIpdSocietyCode) {
      this.fetchData();
    }
  },
  methods: {
    async downloadReport(header) {
      try {
        const subType =
          header.report_type === ReportType.PLAYLIST_REPORT ? 'claims' : 'played-recordings';
        const download = await RemoteFileHelper.downloadReportFile(
          'sdeg',
          subType,
          `${header.report_id}.xml`,
        );
        DownloadLink.direct(download.fileData, download.originalFileName);
      } catch (error) {
        this.handleError('Download failed', error);
      }
    },
    async deleteReport(header) {
      try {
        if (header.report_type === ReportType.PLAYLIST_REPORT) {
          await MatchingService.deletePlaylistReport(header.report_id);
        }
        if (header.report_type === ReportType.PLAYED_RECORDING_REPORT) {
          await MatchingService.deletePlayedRecordingReport(header.report_id);
        }
      } catch (error) {
        this.handleError('Delete failed', error);
      } finally {
        await this.fetchData();
      }
    },
    async updateReportPriority(headerIndex, headerPriority) {
      const header = this.reportHeadersResult.items[headerIndex];
      const previousHeaderPriority = header.priority;

      const siblingHeaderIndex =
        headerPriority > previousHeaderPriority ? headerIndex + 1 : headerIndex - 1;
      const siblingHeader = this.reportHeadersResult.items[siblingHeaderIndex];

      header.priority = headerPriority;
      siblingHeader.priority = previousHeaderPriority;

      if (this.tableColumns[0].active) {
        this.reportHeadersResult.items.sort((a, b) => a.priority - b.priority);
      }

      await Promise.all([
        MatchingService.updateReportHeader(header.report_id, { priority: headerPriority }),
        MatchingService.updateReportHeader(siblingHeader.report_id, {
          priority: previousHeaderPriority,
        }),
      ]);
    },
    onFinished(value) {
      this.pagination.currentPage = 1;
      this.finished = value;
      this.updateQueryParams();
    },
    onSocietyFilter(ipdCode) {
      this.societyIpdCodeFilter = ipdCode;
      this.pagination.currentPage = 1;
      this.updateQueryParams();
    },
    deadlinePassed(info) {
      if (!info || info.finished_by) {
        return false;
      }

      return moment(info.deadline).isBefore(moment());
    },
    getSocietyId(code) {
      return this.societies.find((s) => s.code === code)?.id;
    },
    async fetchData() {
      // Used from a society page, lock to the society ipd code
      if (this.$router.currentRoute.params.id) {
        this.societyIpdCodeFilter = this.metaInfoIpdSocietyCode;
        this.finished = true;
      }

      if (!this.societyIpdCodeFilter) {
        this.finished = undefined;
      }

      this.loading = true;
      this.error = false;
      try {
        const request = {
          process_type: 'SDEG',
          report_type: this.$router.currentRoute.name.includes('PlayedRecordings') ? 'PRR' : 'PLR',
          society_code: undefined,
          limit: this.pagination.hitsPerPage,
          offset: this.getOffset(),
          filter: this.filterQuery,
          sort_by: this.sortTerms.param,
          sort_order: this.sortTerms.order,
          finished: this.finished,
          use_cache: true,
        };

        const society = this.societies.find((s) => s.ipd_code === this.societyIpdCodeFilter);
        if (society) {
          request.society_code = society.code;
        }

        const { items, total_count } = await MatchingService.listReportHeaders(request);
        this.reportHeadersResult = {
          total_count,
          items: items.map((r) => ({
            ...r,
            report_state: r.info && r.info.finished_by ? 'F' : r.report_state,
          })),
        };

        this.loading = false;

        const autoReload = this.reportHeadersResult.items.some(
          (reportHeader) =>
            this.isWaitState(reportHeader.report_state) &&
            !this.isWaitingOverdue(reportHeader.report_date),
        );
        if (autoReload && this.timeoutHandle == null) {
          this.timeoutHandle = setTimeout(() => {
            this.timeoutHandle = null;
            this.fetchData();
          }, 20000);
        }
      } catch (error) {
        this.handleError('Error fetching music report headers', error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-extra {
  font-size: 12px;
  font-weight: normal;
}
.fake-ids {
  font-size: 10px;
  color: var(--grey--vlight);
}
</style>
