<template>
  <form class="e2e-search-result__albums">
    <div class="search-result__filters card">
      <div class="row">
        <div class="col s3">
          <label class="label">Search</label>
          <clearable-input
            v-model="searchTerms.query"
            type="search"
            name="filter"
            placeholder=""
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <label class="label">Catalogue No.</label>
          <clearable-input
            v-model="searchTerms.catalogNumber"
            type="search"
            name="filter"
            placeholder=""
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <label class="label">Main Artist</label>
          <clearable-input
            v-model="searchTerms.mainArtist"
            type="text"
            autocomplete="off"
            placeholder="E.g. Kent"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <label class="label">Label</label>
          <clearable-input
            v-model="searchTerms.label"
            type="text"
            autocomplete="off"
            placeholder="E.g. BMG"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s3">
          <searchable-tag-input
            name="tags"
            label="Tags"
            placeholder="E.g. Top 100"
            :preview-list="previewTags"
            :value-list="localTags"
            :searcher="searchTag"
            scope="advanced-search-albums"
            use-template="tag"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
      </div>
      <div class="row flush--bottom">
        <div class="col s12 text--right">
          <action-buttons
            submit-label="Search"
            abort-label="Clear"
            @save="onSearchClick"
            @cancel="onClearClick"
          />
        </div>
      </div>
    </div>

    <div v-show="searchContext.isExecuted" class="search-result__summary">
      Search for
      <span v-if="searchContext.query != ''" class="text--bold">{{ searchContext.query }}</span>
      <span v-else>*</span>
      in Albums gave {{ $filters.formatNumber(searchContext.result.totalHits) }} results
    </div>

    <SearchQueryTooLong
      :length-limit="searchContext.searchLengthLimit"
      :query-too-long="searchContext.isTooLong"
    />

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="search-result__result card row">
        <div class="row">
          <div class="col s12">
            <table>
              <thead>
                <th
                  v-for="(column, index) in columns"
                  :key="`column-${index}`"
                  :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  @click="column.sortName ? sort(column) : null"
                >
                  <span>{{ column.name }}</span>
                  <span v-if="!column.thinColumn && column.sortName" class="dropdown-button__arrow">
                    <i
                      v-if="column.order === 'desc'"
                      class="fas fa-caret-up"
                      :class="{ 'text--grey': !column.active }"
                    />
                    <i v-else class="fas fa-caret-down" :class="{ 'text--grey': !column.active }" />
                  </span>
                </th>
              </thead>
              <tbody>
                <template v-for="result in searchContext.result.results">
                  <tr :key="`${result.id}-tr1`">
                    <td
                      v-if="hasDetails(result)"
                      class="thin-column dropdown-button__arrow"
                      @click="toggleDetails(result.id, $event)"
                    >
                      <i v-if="albumExpanded(result.id)" class="fas fa-caret-up" />
                      <i v-else-if="!albumExpanded(result.id)" class="fas fa-caret-down" />
                    </td>

                    <td v-if="!hasDetails(result)" class="no-details thin-column">
                      <i class="fas fa-caret-down" />
                    </td>

                    <td>
                      <span>
                        <router-link
                          :to="`/albums/${result.id}`"
                          v-html="$filters.highlightToHtml(result.name, searchContext.query)"
                        />
                      </span>
                    </td>

                    <td>
                      <span v-if="result.main_artist && result.main_artist.id">
                        <router-link
                          :to="`/mainartists/${result.main_artist.id}`"
                          class="fs-13"
                          v-html="
                            $filters.highlightToHtml(
                              result.main_artist.name,
                              searchContext.mainArtist,
                            )
                          "
                        />
                      </span>
                      <span v-else class="none">None</span>
                    </td>

                    <td>
                      <span v-if="result.tracks">{{ result.tracks.length }}</span>
                      <span v-else class="none">None</span>
                    </td>

                    <td>
                      <span v-if="result.catalog_number">{{ result.catalog_number }}</span>
                      <span v-else class="none">None</span>
                    </td>

                    <td>
                      <span v-if="result.release_date">
                        {{ $filters.formatDate(result.release_date) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>

                    <td>
                      <span
                        v-if="result.label"
                        v-html="$filters.highlightToHtml(result.label, searchContext.label)"
                      />
                      <span v-else class="none">None</span>
                    </td>

                    <td>
                      <span v-if="result.last_modified">
                        {{ $filters.formatDateTime(Number(result.last_modified)) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                  </tr>

                  <tr v-if="albumExpanded(result.id)" :key="`${result.id}-tr2`" class="expand-info">
                    <td class="thin-column" />
                    <td />
                    <td />
                    <td colspan="4" class="expand-info__meta">
                      <span class="expand-info__header">More meta data</span>
                      <div>
                        <div class="expand-info__meta__title">Tracks:</div>
                        <simple-spinner v-if="!currentTracks[result.id]" />
                        <ul
                          v-else-if="
                            currentTracks[result.id] && currentTracks[result.id].length > 0
                          "
                        >
                          <li v-for="track in currentTracks[result.id]" :key="track.recording.id">
                            <router-link :to="'/recordings/' + track.recording.id">
                              {{ track.recordingAggregate.basic_info.name }}
                              {{ track.recordingAggregate.lineup_locked ? ' 🔒' : '' }}
                            </router-link>
                          </li>
                        </ul>
                        <div
                          v-if="currentTracks[result.id] && currentTracks[result.id].length === 0"
                          class="none"
                        >
                          None
                        </div>
                      </div>
                      <div class="expand-info__meta__tags">
                        <div class="expand-info__meta__tags-title expand-info__meta__title">
                          Tags:
                        </div>
                        <div class="chips__wrapper">
                          <template v-if="result.tags && result.tags.length > 0">
                            <tag v-for="(tag, index) in result.tags" :key="index" :tag="tag" />
                          </template>
                          <div v-else class="none">None</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="searchContext.isExecuted && searchContext.result.totalHits === 0">
                  <tr>
                    <td colspan="1000" class="search-result__no-result">No items found.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="searchContext.isExecuted">
          <div class="col s12">
            <pagination
              v-show="numberOfPages > 0"
              topic="search"
              :number-of-pages="numberOfPages"
              :selected-page="pagination.page"
              :number-of-hits="searchContext.result.totalHits"
              :hits-per-page="pagination.hitsPerPage"
              :range-limit="true"
              @selectPage="selectPage"
              @updateHitsPerPage="updateHitsPerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import ActionButtons from '../ui/button/action-buttons';
import AlbumService from '../../services/albumService';
import Pagination from './../pagination';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import SearchHelper from './searchHelper';
import Spinner from '../spinner';
import Tag from '../tags/tag';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';
import SimpleSpinner from '@/components/ui/simple-spinner.vue';

export default {
  name: 'AdvancedSearchAlbums',
  components: {
    SimpleSpinner,
    ActionButtons,
    Pagination,
    Spinner,
    SearchableTagInput,
    Tag,
    ClearableInput,
    SearchQueryTooLong,
  },
  data() {
    return {
      loading: false,
      currentTracks: {},
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Album',
          sortName: 'name.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Main Artist',
          sortName: 'main_artist.name.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'No. Tracks',
          sortName: 'number_of_tracks',
          thinColumn: true,
          order: 'desc',
          active: false,
        },
        {
          name: 'Catalogue No.',
          sortName: 'catalog_number',
          order: 'desc',
          active: false,
        },
        {
          name: 'Release Date',
          sortName: 'release_date',
          order: 'desc',
          active: false,
        },
        {
          name: 'Label',
          sortName: 'label.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Last modified',
          sortName: 'last_modified',
          order: 'desc',
          active: false,
        },
      ],
      searchTerms: {
        query: '',
        catalogNumber: '',
        mainArtist: '',
        label: '',
        tags: [],
      },
      sortTerms: {
        param: '',
        order: '',
      },
      defaultHitsPerPage: 25,
      expandedAlbumIds: [],
      allTags: [],
      localTags: [],
      searchContext: {
        query: '',
        mainArtist: '',
        label: '',
        isExecuted: false,
        isTooLong: false,
        searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
        result: {
          totalHits: 0,
          results: [],
        },
      },
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    previewTags() {
      return this.allTags
        .filter((tag) =>
          this.localTags.length > 0
            ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
            : true,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
    },
  },
  watch: {
    $route() {
      this.updateRouterSearchParams();
      if (this.$router.currentRoute.query.i) {
        this.search();
      } else {
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        this.$emit('updateIsExecuted', false);
      }
    },
  },
  async created() {
    this.allTags = await SearchHelper.getAllTags();
    if (this.$router.currentRoute.query.i) {
      this.updateRouterSearchParams();
      this.search();
    }
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.searchTerms.tags = event.data.map((tag) => tag.name);
      this.localTags = event.data;
    },
    removeTagAt(event) {
      this.searchTerms.tags.splice(event.idx, 1);
    },
    addMainArtist(mainArtist) {
      this.searchTerms.mainArtist = mainArtist ? mainArtist.name : '';
    },
    hasDetails(album) {
      return (album.tracks && album.tracks.length > 0) || (album.tags && album.tags.length > 0);
    },
    toggleDetails(id, e) {
      const index = _.indexOf(this.expandedAlbumIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedAlbumIds.push(id);
        row.style.whiteSpace = 'normal';
        AlbumService.getTracksByAlbum(id).then((tracks) => {
          this.$set(this.currentTracks, id, tracks);
        });
      } else {
        this.expandedAlbumIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
    },
    albumExpanded(id) {
      return _.includes(this.expandedAlbumIds, id);
    },
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterSearchParams() {
      const routerParams = this.$router.currentRoute.params;
      const routerQuery = this.$router.currentRoute.query;
      this.searchTerms.query = routerParams.query ? routerParams.query : '';
      this.searchTerms.mainArtist = routerQuery.mainArtist ? routerQuery.mainArtist : '';
      this.searchTerms.catalogNumber = routerQuery.catalogNumber ? routerQuery.catalogNumber : '';
      this.searchTerms.label = routerQuery.label ? routerQuery.label : '';
      this.searchTerms.tags = this.$router.currentRoute.query.tags
        ? this.$router.currentRoute.query.tags
        : [];
      if (this.$router.currentRoute.query.tags) {
        if (_.isArray(this.$router.currentRoute.query.tags)) {
          this.localTags = this.$router.currentRoute.query.tags.map((tag) => ({
            name: tag,
            type: 'tag',
          }));
        } else {
          this.localTags = [{ name: this.$router.currentRoute.query.tags, type: 'tag' }];
        }
      } else {
        this.localTags = [];
      }
      this.sortTerms.param = routerQuery.sortParam ? routerQuery.sortParam : '';
      this.sortTerms.order = routerQuery.sortOrder ? routerQuery.sortOrder : '';
      this.pagination.page = routerQuery.page ? +routerQuery.page : 1;
      this.pagination.hitsPerPage = routerQuery.hits ? +routerQuery.hits : this.defaultHitsPerPage;
    },
    onSearchClick() {
      this.resetPages();
      this.pushSearch();
    },
    onClearClick() {
      this.$router.push({
        name: 'advancedSearchAlbumsView',
        query: '',
      });
    },
    pushSearch() {
      const queryParams = {
        i: new Date().getTime(),
      };
      if (this.searchTerms.mainArtist) {
        queryParams.mainArtist = this.searchTerms.mainArtist;
      }
      if (this.searchTerms.catalogNumber) {
        queryParams.catalogNumber = this.searchTerms.catalogNumber;
      }
      if (this.searchTerms.label) {
        queryParams.label = this.searchTerms.label;
      }
      if (this.searchTerms.tags) {
        queryParams.tags = this.searchTerms.tags;
      }
      if (this.sortTerms.param) {
        queryParams.sortParam = this.sortTerms.param;
      }
      if (this.sortTerms.order) {
        queryParams.sortOrder = this.sortTerms.order;
      }
      if (this.pagination.hitsPerPage) {
        queryParams.hits = this.pagination.hitsPerPage;
      }
      if (this.pagination.page) {
        queryParams.page = this.pagination.page;
      }
      if (this.searchTerms.query !== '') {
        this.$router.push({
          name: 'advancedSearchAlbumsQueryView',
          params: { query: this.searchTerms.query },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'advancedSearchAlbumsView',
          query: queryParams,
        });
      }
    },
    search() {
      this.searchContext.query = this.searchTerms.query;
      this.searchContext.mainArtist = this.searchTerms.mainArtist;
      this.searchContext.label = this.searchTerms.label;
      if (SearchHelper.isQueryTooLong(this.searchTerms.query)) {
        this.searchContext.isTooLong = true;
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        return;
      }
      this.searchContext.isTooLong = false;
      this.loading = true;
      SearchHelper.advancedSearchAlbums(
        this.searchTerms.query,
        this.searchTerms.mainArtist,
        this.searchTerms.catalogNumber,
        this.searchTerms.label,
        this.searchTerms.tags,
        this.sortTerms.param,
        this.sortTerms.order,
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage,
        this.pagination.hitsPerPage,
      )
        .then((result) => {
          this.searchContext.isExecuted = true;
          this.$emit('updateIsExecuted', true);
          this.searchContext.result = result;
        })
        .then(() => {
          this.loading = false;
        });
    },
    resetPages() {
      this.sortTerms = { param: '', order: '' };
      this.pagination.page = 1;
    },
    sort: function sort(column) {
      this.sortTerms.param = column.sortName;
      column.order = column.order === 'desc' ? 'asc' : 'desc';
      this.sortTerms.order = column.order;
      this.columns.forEach((c) => {
        c.active = false;
      });
      column.active = true;
      this.pushSearch();
      this.search();
    },
  },
};
</script>
