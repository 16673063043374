<template>
  <div class="searchable-input">
    <div class="searchable-input__input-component" :style="cssProps">
      <label
        v-if="showLabel"
        :class="{
          label: true,
          'label--empty': !label,
          'label--error': validationErrors.has(name, scope),
        }"
      >
        {{ label }}
        <span v-if="mandatory" class="mandatory">(mandatory)</span>
      </label>
      <input
        v-validate="computedRules"
        type="search"
        autocomplete="off"
        :name="name"
        :placeholder="placeholder"
        :class="{ input: true, 'is-danger': validationErrors.has(name, scope) }"
        :value="query"
        :data-vv-scope="scope"
        :data-vv-as="label.toLowerCase()"
        @input="updateQuery($event.target.value)"
        @keydown.down="openSearchResults()"
        @click="openSearchResults()"
        @keydown.prevent.stop.down="nextHit"
        @keydown.prevent.stop.up="previousHit"
        @keydown.prevent.stop.esc="clearSearch"
        @keydown.prevent.stop.enter="selected"
        @blur="closeSearchResults()"
      />

      <span v-show="validationErrors.has(name, scope)" class="help is-danger">
        <i class="fas fa-times-circle" />
        {{ validationErrors.first(name, scope) }}
      </span>
    </div>
    <div
      v-show="showDropdown"
      class="searchable-input__autocomplete"
      :class="{ 'searchable-input__autocomplete--pushleft': screenEdgeReached }"
    >
      <generic-error v-if="error" :error="error" />
      <table v-else>
        <thead>
          <tr class="header-row">
            <template v-for="(entry, index) in resultTemplate">
              <th :key="index" class="nowrap">
                {{ entry.label }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="searchResult.length > 0">
            <tr
              v-for="(result, resultIndex) in searchResult"
              :key="resultIndex"
              class="result-row"
              :class="{
                selected: resultIndex === selectedIndex,
                inactive: inactiveIds.includes(result.id),
              }"
              :disabled="inactiveIds.includes(result.id)"
              @mouseover.prevent="setSelectedIndex(resultIndex)"
              @keyup.enter="setSelectedIndex(resultIndex)"
              @mousedown.prevent="selected"
            >
              <template v-for="(entry, index) in resultTemplate">
                <td v-if="entry.icon" :key="index" class="nowrap">
                  <span v-if="entry.field === 'mandates' && getValue(result, entry)">
                    <mandate-list :mandates="getValue(result, entry)" />
                  </span>
                </td>
                <td
                  v-else-if="entry.field === 'distribution_state.status' && getValue(result, entry)"
                  :key="index"
                >
                  <status-icon class="col s1" :status="getValue(result, entry)" />
                </td>
                <td v-else :key="index">
                  <span
                    v-if="entry.highlight"
                    v-html="$filters.highlightToHtml(getValue(result, entry), query)"
                  />
                  <span v-else v-html="getValue(result, entry)" />
                </td>
              </template>
            </tr>
          </template>
          <template v-else-if="isTooLongQuery">
            <SearchQueryTooLong
              :length-limit="searchLengthLimit"
              :query-too-long="isTooLongQuery"
            />
          </template>
          <template v-else>
            <tr class="result-row">
              <td>No results.</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import DebouncedSearchMixin from '../../../common/debouncedSearchMixin';
import Templates from './searchable-input-templates';
import MandateList from '../performer/mandate-list';
import StatusIcon from '../../ui/status-icon';
import SearchQueryTooLong from '@/components/search/search-query-too-long';
import SearchHelper from '@/components/search/searchHelper';

export default {
  name: 'SearchableInput',
  components: {
    MandateList,
    StatusIcon,
    SearchQueryTooLong,
  },
  mixins: [DebouncedSearchMixin(350)],
  inject: ['$validator'],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    searcher: {
      type: Function,
      required: true,
    },
    searcherExtraArgs: {
      type: Array,
      default: () => [],
    },
    inactiveIds: {
      type: Array,
      default: () => [],
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: '',
    },
    clearAfterSelect: {
      type: Boolean,
      default: false,
    },
    useTemplate: {
      type: String,
      required: true,
    },
    cssProps: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchResult: [],
      selectedIndex: -1,
      showDropdown: false,
      screenEdgeReached: false,
      query: this.value || '',
      resultTemplate: [],
      error: false,
      isTooLongQuery: false,
      searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
    };
  },
  computed: {
    computedRules() {
      const ruleCopy = _.cloneDeep(this.rule);
      if (_.isObject(ruleCopy) && _.has(this.rule, 'requiredValue')) {
        ruleCopy.required = true;
      }
      return ruleCopy;
    },
  },
  watch: {
    value() {
      this.query = this.value;
    },
  },
  methods: {
    setScreenEdgeReached() {
      if (!this.$el) {
        this.screenEdgeReached = false;
      } else {
        const rect = this.$el.getBoundingClientRect();
        const acRect = this.$el
          .querySelector('.searchable-input__autocomplete')
          .getBoundingClientRect();
        const distanceToRight = window.innerWidth - (rect.left + acRect.width);
        this.screenEdgeReached = distanceToRight < 0;
      }
    },
    updateQuery(terms) {
      if (!terms) {
        this.$emit('input', null);
      }
      this.$emit('change', terms);
      this.query = terms;
      this.debouncedSearch(this.query);
    },
    search(terms) {
      if (SearchHelper.isQueryTooLong(terms)) {
        this.isTooLongQuery = true;
        this.searchResult = [];
        return;
      }
      this.isTooLongQuery = false;
      this.error = false;
      this.clearSelectedIndex();
      this.searcher(terms, ...this.searcherExtraArgs)
        .then((result) => {
          if (this.useTemplate) {
            this.resultTemplate = Templates[this.useTemplate];
          } else {
            this.resultTemplate = result[0] ? Templates[result[0].type] : [];
          }
          this.searchResult = result;
          if (this.searchResult) {
            this.openSearchResults();
          }
        })
        .catch((error) => {
          if (error.type === 'MIN_CHAR') {
            this.searchResult = [];
            this.closeSearchResults();
          } else {
            this.openSearchResults();
            error.title = 'Search failed';
            this.$addStarError(error);
          }
        });
    },
    selected() {
      let selectedHit = null;
      if (this.selectedIndex !== -1) {
        selectedHit = this.searchResult[this.selectedIndex];
        this.query = selectedHit.display_name || selectedHit.name;
      }
      if (this.selectedIndex === -1 && this.query && this.query.length > 0) {
        selectedHit = { name: this.query, id: null };
      }
      this.closeSearchResults();
      if (this.clearAfterSelect) {
        this.query = '';
      }
      this.$emit('input', selectedHit);
    },
    setSelectedIndex(index) {
      this.selectedIndex = index;
    },
    nextHit(idx) {
      let i;
      if (Number.isInteger(idx)) {
        i = idx;
      } else {
        i = 1;
      }
      if (this.isInactive(this.selectedIndex + i)) {
        this.nextHit(i + 1);
        return;
      }
      this.selectedIndex =
        this.selectedIndex + 1 < this.searchResult.length ? this.selectedIndex + i : -1;
    },
    previousHit(idx) {
      let i;
      if (Number.isInteger(idx)) {
        i = idx;
      } else {
        i = 1;
      }
      if (this.isInactive(this.selectedIndex - i)) {
        this.previousHit(i + 1);
        return;
      }
      this.selectedIndex = this.selectedIndex - i;

      if (this.selectedIndex === -2) {
        this.selectedIndex = this.searchResult.length;
        this.previousHit();
      }
    },
    openSearchResults() {
      if (this.searchResult && this.query && this.query.length > 0) {
        this.showDropdown = true;
        this.$nextTick(() => {
          this.setScreenEdgeReached();
        });
      }
    },
    getValue(result, entry) {
      let valueWithTransformations = _.get(result, entry.field, null);
      if (entry.transform) {
        valueWithTransformations = entry.transform(valueWithTransformations);
      }
      if (entry.translationPrefix) {
        valueWithTransformations = this.$filters.translate(
          `${entry.translationPrefix}.${valueWithTransformations}`,
        );
      }
      if (entry.filter) {
        if (this.$filters[entry.filter]) {
          valueWithTransformations = this.$filters[entry.filter](valueWithTransformations);
        }
      }
      return valueWithTransformations;
    },
    clearSelectedIndex() {
      this.setSelectedIndex(-1);
    },
    closeSearchResults() {
      this.showDropdown = false;
      this.clearSelectedIndex();
    },
    clearSearch(e) {
      if (!e || !e.relatedTarget || !e.relatedTarget.hasAttribute('data-event')) {
        this.query = '';
        this.closeSearchResults();
        this.$emit('clear', null);
      }
    },
    isInactive(idx) {
      if (idx !== -1) {
        if (this.searchResult[idx]) {
          return this.inactiveIds.includes(this.searchResult[idx].id);
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.searchable-input {
  &__input-component__arrow {
    position: absolute;
    top: 0;
    right: 0;
  }

  .input {
    background-color: var(--white);
    color: var(--grey--dark);
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__tags {
    position: absolute;
    height: 500px;
    width: 100%;
    z-index: 100;
    background-color: var(--white);
    border: 1px solid var(--grey--dark);
    padding-top: var(--spacing);
    margin-bottom: var(--spacing-sixfold);
    overflow-y: scroll;
  }

  &__autocomplete {
    position: absolute;
    min-width: 50vw;
    width: 100%;
    max-width: 100vw;
    z-index: 100;
    background-color: var(--white);
    border: 1px solid var(--grey--dark);
    padding-top: var(--spacing);
    margin-bottom: var(--spacing-sixfold);

    &--pushleft {
      right: 0;
      left: auto;
    }

    .result-row {
      cursor: pointer;
      color: var(--grey--dark);

      i {
        float: left;
      }

      &.inactive {
        color: var(--grey--vlight);
        text-decoration: line-through;

        .text--blue {
          color: var(--grey--vlight) !important;
        }
      }

      &.selected {
        background-color: var(--list-hover-blue);
      }
      &:hover {
        background-color: var(--list-hover-blue);
      }
    }
  }
}
</style>
