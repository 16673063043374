<template>
  <form class="search-result__mainartists">
    <div class="search-result__filters card">
      <div class="row">
        <div class="col s3">
          <label class="label">Search</label>
          <clearable-input
            v-model="searchTerms.query"
            type="search"
            name="filter"
            placeholder=""
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s3">
          <select-country
            v-model="searchTerms.originCountry"
            label="Country of Origin"
            :input-countries="localCountries"
          />
        </div>
        <div class="col s3">
          <select-genres v-model="searchTerms.genre" label="Genre" />
        </div>
        <div class="col s3">
          <searchable-tag-input
            name="tags"
            label="Tags"
            placeholder="E.g. Top 100"
            :preview-list="previewTags"
            :value-list="localTags"
            :searcher="searchTag"
            scope="advanced-search-mainartists"
            use-template="tag"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
      </div>
      <div class="row flush--bottom">
        <div class="col s12 text--right">
          <action-buttons
            submit-label="Search"
            abort-label="Clear"
            @save="onSearchClick"
            @cancel="onClearClick"
          />
        </div>
      </div>
    </div>

    <div v-show="searchContext.isExecuted" class="search-result__summary">
      Search for
      <span v-if="searchContext.query != ''" class="text--bold">{{ searchContext.query }}</span>
      <span v-else>*</span>
      in Main artists gave
      {{ $filters.formatNumber(searchContext.result.totalHits) }} results
    </div>

    <SearchQueryTooLong
      :length-limit="searchContext.searchLengthLimit"
      :query-too-long="searchContext.isTooLong"
    />

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="search-result__result card row">
        <div class="row">
          <div class="col s12">
            <table>
              <thead>
                <th
                  v-for="(column, index) in columns"
                  :key="`column-${index}`"
                  :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  @click="column.sortName ? sort(column) : ''"
                >
                  <span>{{ column.name }}</span>
                  <span v-if="column.sortName && !column.thinColumn" class="dropdown-button__arrow">
                    <i
                      v-if="column.order === 'desc'"
                      class="fas fa-caret-up"
                      :class="{ 'text--grey': !column.active }"
                    />
                    <i v-else class="fas fa-caret-down" :class="{ 'text--grey': !column.active }" />
                  </span>
                </th>
              </thead>
              <tbody>
                <template v-for="result in searchContext.result.results">
                  <tr :key="`${result.id}-tr1`">
                    <td
                      v-if="hasDetails(result)"
                      class="thin-column dropdown-button__arrow"
                      @click="toggleDetails(result.id, $event)"
                    >
                      <i v-if="mainArtistExpanded(result.id)" class="fas fa-caret-up" />
                      <i v-else-if="!mainArtistExpanded(result.id)" class="fas fa-caret-down" />
                    </td>
                    <td
                      v-if="!hasDetails(result)"
                      class="no-details thin-column dropdown-button__arrow"
                    >
                      <i class="fas fa-caret-down" />
                    </td>
                    <td>
                      <span>
                        <router-link
                          :to="`/mainartists/${result.id}`"
                          v-html="$filters.highlightToHtml(result.name, searchContext.query)"
                        />
                      </span>
                    </td>
                    <td>
                      <span v-if="result.active_period && result.active_period.length > 0">
                        {{ result.active_period[0].from }} - {{ result.active_period[0].to }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.origin_country">
                        {{ $filters.formatCountry(result.origin_country) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.genre">
                        {{ $filters.formatGenre(result.genre) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.sami_members">
                        <i class="fas fa-user" />
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                  </tr>
                  <tr
                    v-if="mainArtistExpanded(result.id)"
                    :key="`${result.id}-tr2`"
                    class="expand-info"
                  >
                    <td class="thin-column" />
                    <td>
                      <span class="expand-info__header">Alternate names</span>
                      <div v-if="result.alternate_titles && result.alternate_titles.length > 0">
                        <template v-for="alternate_title in result.alternate_titles">
                          {{ alternate_title }}
                        </template>
                      </div>
                      <div v-if="!result.alternate_titles" class="none">None</div>
                    </td>
                    <td class="expand-info">
                      <div class="expand-info__header">Years active</div>
                      <div v-if="result.active_period && result.active_period.length > 1">
                        <template v-for="activePeriod in result.active_period">
                          {{ activePeriod.from }} - {{ activePeriod.to }}
                        </template>
                      </div>
                      <div
                        v-if="result.active_period && result.active_period.length === 0"
                        class="none"
                      >
                        None
                      </div>
                    </td>
                    <td
                      v-if="
                        (result.performers && result.performers.length > 0) ||
                        (result.tags && result.tags.length > 0)
                      "
                      colspan="2"
                      class="expand-info__meta"
                    >
                      <simple-spinner v-if="!currentPerformers[result.id]" />
                      <template v-else>
                        <div>
                          <span class="expand-info__header">More meta data</span>
                          <span class="expand-info__meta__title">Performers:</span>
                          <div v-if="result.performers && result.performers.length > 10">
                            <div class="expand-info__lineup">
                              <template v-for="(performer, index) in currentPerformers[result.id]">
                                <div v-if="index < 10" :key="performer.id">
                                  <router-link :to="'/performers/' + performer.id">
                                    {{ performer.name }}
                                    {{ performer.protected_identity ? ' 🔒' : '' }}
                                  </router-link>
                                </div>
                              </template>
                            </div>
                            <div class="expand-info__lineup__more-exists">
                              {{ result.performers.length - 10 }} more
                            </div>
                          </div>
                          <div v-else-if="result.performers.length === 0" class="none">None</div>
                          <div v-else>
                            <router-link
                              v-for="performer in currentPerformers[result.id]"
                              :key="performer.id"
                              :to="'/performers/' + performer.id"
                            >
                              {{ performer.name }}
                              {{ performer.protected_identity ? ' 🔒' : '' }}
                            </router-link>
                          </div>
                        </div>
                        <div>
                          <span
                            v-if="result.performers.length > 0"
                            class="expand-info__meta__title"
                          >
                            Society:
                          </span>
                          <div v-if="result.performers.length > 10">
                            <div class="expand-info__lineup">
                              <div
                                v-for="(performer, index) in result.performers"
                                :key="performer.id"
                              >
                                <template v-if="index < 10">
                                  {{ performer.society }}
                                </template>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <template v-for="performer in result.performers">
                              <router-link :key="performer.id" :to="'/performers/' + performer.id">
                                {{ performer.society }}
                              </router-link>
                            </template>
                          </div>
                        </div>
                        <div class="expand-info__meta__tags">
                          <div class="expand-info__meta__tags-title expand-info__meta__title">
                            Tags:
                          </div>
                          <div class="chips__wrapper">
                            <tag v-for="(tag, index) in result.tags" :key="index" :tag="tag" />
                            <div v-if="!result.tags" class="none">None</div>
                          </div>
                        </div>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-if="searchContext.isExecuted && searchContext.result.totalHits === 0">
                  <tr>
                    <td colspan="1000" class="search-result__no-result">No items found.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="searchContext.isExecuted">
          <div class="col s12">
            <pagination
              v-show="numberOfPages > 0"
              topic="search"
              :number-of-pages="numberOfPages"
              :selected-page="pagination.page"
              :number-of-hits="searchContext.result.totalHits"
              :hits-per-page="pagination.hitsPerPage"
              :range-limit="true"
              @selectPage="selectPage"
              @updateHitsPerPage="updateHitsPerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';

import ActionButtons from '../ui/button/action-buttons';
import Pagination from '../pagination';
import SearchHelper from './searchHelper';
import SelectCountry from '../ui/select/select-country';
import SelectGenres from '../ui/select/select-genre';
import Spinner from '../spinner';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import Tag from '../tags/tag';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';
import MainArtistService from '@/services/mainArtistService';
import SimpleSpinner from '@/components/ui/simple-spinner.vue';

export default {
  name: 'AdvancedSearchMainartists',
  components: {
    SimpleSpinner,
    ActionButtons,
    Pagination,
    SelectCountry,
    SelectGenres,
    Spinner,
    SearchableTagInput,
    Tag,
    ClearableInput,
    SearchQueryTooLong,
  },
  data() {
    return {
      loading: false,
      localCountries: [],
      currentPerformers: {},
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Name',
          sortName: 'name.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Years Active',
        },
        {
          name: 'Country of Origin',
          sortName: 'origin_country',
          order: 'desc',
          active: false,
        },
        {
          name: 'Genre',
          sortName: 'genre',
          order: 'desc',
          active: false,
        },
        {
          name: 'Member',
        },
      ],
      allTags: [],
      localTags: [],
      searchTerms: {
        query: '',
        originCountry: '',
        genre: '',
        tags: [],
      },
      sortTerms: {
        param: '',
        order: '',
      },
      defaultHitsPerPage: 25,
      expandedMainArtistIds: [],
      searchContext: {
        query: '',
        isExecuted: false,
        isTooLong: false,
        searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
        result: {
          totalHits: 0,
          results: [],
        },
      },
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    previewTags() {
      return this.allTags
        .filter((tag) =>
          this.localTags && this.localTags.length > 0
            ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
            : true,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
    },
    firstHitIndexOnCurrentPage() {
      if (this.searchContext.result.totalHits === 0) {
        return 0;
      }
      return Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage + 1;
    },
    lastHitIndexOnCurrentPage() {
      const totalHits = this.searchContext.result.totalHits;
      const lastHitIndex =
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage +
        this.pagination.hitsPerPage;
      return lastHitIndex < totalHits ? lastHitIndex : totalHits;
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    $route() {
      this.updateRouterSearchParams();
      if (this.$router.currentRoute.query.i) {
        this.search();
      } else {
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        this.$emit('updateIsExecuted', false);
      }
    },
  },
  async created() {
    this.localCountries = this.countries.slice();
    this.allTags = await SearchHelper.getAllTags();
    if (this.$router.currentRoute.query.i) {
      this.updateRouterSearchParams();
      this.search();
    }
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.searchTerms.tags = event.data.map((tag) => tag.name);
      this.localTags = event.data;
    },
    removeTagAt(event) {
      this.searchTerms.tags.splice(event.idx, 1);
    },
    hasDetails(mainArtist) {
      return (
        (mainArtist.alternate_titles && mainArtist.alternate_titles.length > 0) ||
        (mainArtist.performers && mainArtist.performers.length > 0) ||
        (mainArtist.tags && mainArtist.tags.length > 0)
      );
    },
    async toggleDetails(id, e) {
      const index = _.indexOf(this.expandedMainArtistIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedMainArtistIds.push(id);
        row.style.whiteSpace = 'normal';
        const result = await MainArtistService.getMainArtistPerformers(id);
        this.$set(this.currentPerformers, id, result.performers);
      } else {
        this.expandedMainArtistIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
    },
    mainArtistExpanded(id) {
      return _.includes(this.expandedMainArtistIds, id);
    },
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterSearchParams() {
      this.searchTerms.query = this.$router.currentRoute.params.query
        ? this.$router.currentRoute.params.query
        : '';
      this.searchTerms.originCountry = this.$router.currentRoute.query.originCountry
        ? this.$router.currentRoute.query.originCountry
        : '';
      this.searchTerms.genre = this.$router.currentRoute.query.genre
        ? this.$router.currentRoute.query.genre
        : '';
      this.searchTerms.tags = this.$router.currentRoute.query.tags
        ? this.$router.currentRoute.query.tags
        : [];
      if (this.$router.currentRoute.query.tags) {
        if (_.isArray(this.$router.currentRoute.query.tags)) {
          this.localTags = this.$router.currentRoute.query.tags.map((tag) => ({
            name: tag,
            type: 'tag',
          }));
        } else {
          this.localTags = [{ name: this.$router.currentRoute.query.tags, type: 'tag' }];
        }
      } else {
        this.localTags = [];
      }
      this.sortTerms.param = this.$router.currentRoute.query.sortParam
        ? this.$router.currentRoute.query.sortParam
        : '';
      this.sortTerms.order = this.$router.currentRoute.query.sortOrder
        ? this.$router.currentRoute.query.sortOrder
        : '';
      this.pagination.page = this.$router.currentRoute.query.page
        ? Number(this.$router.currentRoute.query.page)
        : 1;
      this.pagination.hitsPerPage = this.$router.currentRoute.query.hits
        ? Number(this.$router.currentRoute.query.hits)
        : this.defaultHitsPerPage;
    },
    onSearchClick() {
      this.resetPages();
      this.pushSearch();
    },
    onClearClick() {
      this.$router.push({
        name: 'advancedSearchMainArtistsView',
        query: '',
      });
    },
    pushSearch() {
      const queryParams = {
        i: new Date().getTime(),
      };
      if (this.searchTerms.originCountry) {
        queryParams.originCountry = this.searchTerms.originCountry;
      }
      if (this.searchTerms.genre) {
        queryParams.genre = this.searchTerms.genre;
      }
      if (this.searchTerms.tags) {
        queryParams.tags = this.searchTerms.tags;
      }
      if (this.sortTerms.param) {
        queryParams.sortParam = this.sortTerms.param;
      }
      if (this.sortTerms.order) {
        queryParams.sortOrder = this.sortTerms.order;
      }
      if (this.pagination.hitsPerPage) {
        queryParams.hits = this.pagination.hitsPerPage;
      }
      if (this.pagination.page) {
        queryParams.page = this.pagination.page;
      }
      if (this.searchTerms.query !== '') {
        this.$router.push({
          name: 'advancedSearchMainArtistsQueryView',
          params: { query: this.searchTerms.query },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'advancedSearchMainArtistsView',
          query: queryParams,
        });
      }
    },
    search() {
      this.searchContext.query = this.searchTerms.query;
      if (SearchHelper.isQueryTooLong(this.searchTerms.query)) {
        this.searchContext.isTooLong = true;
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        return;
      }
      this.searchContext.isTooLong = false;
      this.loading = true;
      SearchHelper.advancedSearchMainArtists(
        this.searchTerms.query,
        this.searchTerms.originCountry,
        this.searchTerms.genre,
        this.searchTerms.tags,
        this.sortTerms.param,
        this.sortTerms.order,
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage,
        this.pagination.hitsPerPage,
      )
        .then((result) => {
          this.searchContext.isExecuted = true;
          this.$emit('updateIsExecuted', true);
          this.searchContext.result = result;
        })
        .then(() => {
          this.loading = false;
        });
    },
    resetPages() {
      this.sortTerms = { param: '', order: '' };
      this.pagination.page = 1;
    },
    sort: function sort(column) {
      this.sortTerms.param = column.sortName;
      column.order = column.order === 'desc' ? 'asc' : 'desc';
      this.sortTerms.order = column.order;
      this.columns.forEach((c) => {
        c.active = false;
      });
      column.active = true;
      this.pushSearch();
      this.search();
    },
  },
};
</script>
